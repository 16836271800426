import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import { X } from "lucide-react";
import html2canvas from "html2canvas";
import furdexo_logo from "../../static/images/furdexo-logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomerInvoice,
  getCustomerInvoice,
} from "../../actions/invoiceActions";
import CurrentDate from "../../utils/currentDate";

const InvoiceGenerator = ({
  isOpen,
  onClose,
  orderDetails,
  customerDetails,
  selectGSTCompany,
  project,
}) => {
  console.log(
    "====orderDetails",
    orderDetails,
    customerDetails,
    selectGSTCompany,
    project
  );
  const dispatch = useDispatch();
  const invoiceRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isExcelGenerating, setIsExcelGenerating] = useState(false);
  const { invoice, apiLoading, apiSuccess } = useSelector(
    (state) => state.invoice
  );

  const [itemsOrderSummary, setItemsOrderSummary] = useState({
    invoiceDate: CurrentDate(),
    invoiceNumber: "",
    grandAmount: 0,
    gstAmount: 0,
  });
  const furdexoInvoiceTC = [
    ["• Above prices are EX-Works Faridabad"],
    ["• Order once confirmed cannot be canceled/modified"],
    ["• Freight/transport expenses will be charged extra on actuals"],
    ["• Payment Terms - 50% Advance with Order & Balance 50% before delivery"],
    [
      "• All QC Checks or shortages shall be reported within 24 hours of supply",
    ],
    ["• Our responsibility ceases once goods leave our premises"],
    ["• Special/Wooden Packing Charges will be extra, if required"],
    ["• We don't undertake any installations or onsite work/Measurements"],
    ["• Wood work prices do not include any hardware, hinges or handles etc"],
    [
      "• Company reserves the right to discontinue or change any product's specifications/price without any information",
    ],
    [
      "• Delivery as per production schedule - approx 15 to 30 days after order confirmation",
    ],
  ];
  const generatePDF = async () => {
    setIsGenerating(true);
    const element = invoiceRef.current;

    try {
      // Create high quality canvas
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      // Calculate dimensions to maintain aspect ratio on A4
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      // Create PDF with correct dimensions
      const pdf = new jsPDF("p", "mm", "a4");
      let pageData = canvas.toDataURL("image/jpeg", 1.0);

      // Add first page
      pdf.addImage(
        pageData,
        "JPEG",
        0,
        position,
        imgWidth,
        imgHeight,
        "",
        "FAST"
      );
      heightLeft -= pageHeight;

      // Add subsequent pages if needed
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          pageData,
          "JPEG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      await addCustomerInvoice(dispatch, {
        customerId: customerDetails._id,
        invoiceNumber: itemsOrderSummary.invoiceNumber,
      });
      // Save the PDF
      pdf.save(`Invoice-${itemsOrderSummary.invoiceNumber}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsGenerating(false);
    }
  };
  const generateExcel = async () => {
    setIsExcelGenerating(true);

    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Invoice");

      // Add an image to the worksheet
      const imagePath = "furdexo-logo.png"; // Path to your image in the public folder
      const image = await fetch(imagePath) // Fetch the image as a Blob
        .then((response) => response.arrayBuffer()) // Convert the image to an ArrayBuffer
        .then((buffer) => buffer);

      const imageId = workbook.addImage({
        buffer: image, // Pass the image buffer here
        extension: "png", // Extension of the image
      });

      // Position the image (e.g., starting at A3) and specify its size
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 0 }, // Position the top-left corner at A3
        ext: { width: 100, height: 30 }, // Set image size (100x100 px)
      });
      // Merge cells A1 to C1 for title
      let row = 1;
      worksheet.mergeCells(`A${row}:E${row}`);

      // Merge cells A1 to C1 for title
      worksheet.mergeCells(`G${row}:N${row}`);
      var mergedCell = worksheet.getCell(`G${row}`);
      mergedCell.value = "ESTIMATE / PRO FORMA INVOICE"; // Set a custom title in the merged cell
      mergedCell.font = { bold: true, size: 14, color: { argb: "8394C9" } };
      mergedCell.alignment = { horizontal: "center", vertical: "middle" };

      const row1 = worksheet.getRow(1);
      row1.height = 30;
      row += 1;
      console.log("====row", row);
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = "MODULAR MANUFACTURING MADE EASY"; // Set a custom title in the merged cell
      mergedCell.font = { size: 11 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      worksheet.mergeCells(`K${row}:M${row}`);
      mergedCell = worksheet.getCell(`K${row}`);
      mergedCell.value = `Date`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell = worksheet.getCell(`N${row}`);
      mergedCell.value = itemsOrderSummary.invoiceDate; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = furdexoCompany.companyName; // Set a custom title in the merged cell
      mergedCell.font = { size: 11 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      worksheet.mergeCells(`K${row}:M${row}`);
      mergedCell = worksheet.getCell(`K${row}`);
      mergedCell.value = `Invoice #`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell = worksheet.getCell(`N${row}`);
      mergedCell.value = itemsOrderSummary.invoiceNumber; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `GSTIN: ${furdexoCompany.companyGST}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 11 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      worksheet.mergeCells(`K${row}:M${row}`);
      mergedCell = worksheet.getCell(`K${row}`);
      mergedCell.value = `Project Name`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell = worksheet.getCell(`N${row}`);
      mergedCell.value = project.projectName; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = ` ${furdexoCompany.companyAddress1}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 11 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = ` ${furdexoCompany.companyAddress1}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 11 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = ` ${furdexoCompany.companyAddress2}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 11 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `Phone: ${furdexoCompany.companyContactPhoneNo}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 11 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `Email: ${furdexoCompany.companyEmailId}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 11 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };

      row += 2;

      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = "CUSTOMER"; // Set a custom title in the merged cell
      mergedCell.font = { bold: true, size: 14, color: { argb: "FFFFFF" } };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "3B4E87" },
      }; // Yellow background
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `${customerDetails.firstName} ${customerDetails.lastName}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `${customerDetails.companyName}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `GSTIN: ${selectGSTCompany}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `${customerDetails.address}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `${customerDetails.city}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `${customerDetails.whatsappNo}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      row += 1;
      worksheet.mergeCells(`A${row}:E${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `${customerDetails.email}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };

      row += 1;
      worksheet.addRow([
        "S.NO.",
        "CATEGORY",
        "ITEM",
        "",
        "",
        "",
        "",
        "HSN CODE",
        "UNIT",
        "QTY",
        "UNIT PRICE",
        "GST",
        "DISCOUNT",
        "TOTAL AMOUNT",
      ]);
      worksheet.mergeCells(`C${row}:G${row}`);
      let rowNumber = worksheet.getRow(row);
      rowNumber.eachCell((cell, colNumber) => {
        // Apply to columns A to D
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "4CAF50" }, // Green background color
        };
        cell.font = { bold: true, color: { argb: "FFFFFF" } }; // White text with bold font
        cell.alignment = { horizontal: "center", vertical: "middle" }; // Center text alignment
      });
      invoiceItemsData.forEach((item, index) => {
        worksheet.addRow([
          index + 1,
          item.categoryName,
          item.name,
          "",
          "",
          "",
          "",
          item.hsnCode,
          item.unitOfMeasurement,
          item.quantity,
          parseFloat(item.ratePerUnit.toFixed(2)),
          parseFloat(item.gstAmount.toFixed(2)),
          parseFloat(item.discountAmount.toFixed(2)),
          parseFloat(item.grandAmount.toFixed(2)),
        ]);
        row += 1;
        worksheet.mergeCells(`C${row}:G${row}`);
        const formatCellK = worksheet.getCell(`K${row}`);
        formatCellK.numFmt = "0.00";
        const formatCellL = worksheet.getCell(`L${row}`);
        formatCellL.numFmt = "0.00";
        const formatCellM = worksheet.getCell(`M${row}`);
        formatCellM.numFmt = "0.00";
        const formatCellN = worksheet.getCell(`N${row}`);
        formatCellN.numFmt = "0.00";
      });
      row += 1;
      worksheet.mergeCells(`A${row}:G${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = "BANK DETAILS"; // Set a custom title in the merged cell
      mergedCell.font = { bold: true, size: 14, color: { argb: "FFFFFF" } };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "666666" },
      }; // Yellow background
      worksheet.mergeCells(`K${row}:M${row}`);
      mergedCell = worksheet.getCell(`K${row}`);
      mergedCell.value = `Subtotal`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell = worksheet.getCell(`N${row}`);
      mergedCell.value = parseFloat(
        (itemsOrderSummary.grandAmount - itemsOrderSummary.gstAmount).toFixed(2)
      ); // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { vertical: "middle" };
      mergedCell.numFmt = "0.00";
      row += 1;
      worksheet.mergeCells(`A${row}:J${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `A/c Holder's Name : ${furdexoCompany.companyBankDetails.accountHolder}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      worksheet.mergeCells(`K${row}:M${row}`);
      mergedCell = worksheet.getCell(`K${row}`);
      mergedCell.value = `Taxes`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell = worksheet.getCell(`N${row}`);
      mergedCell.value = parseFloat(itemsOrderSummary.gstAmount.toFixed(2)); // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { vertical: "middle" };
      mergedCell.numFmt = "0.00";

      row += 1;
      worksheet.mergeCells(`A${row}:J${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `Bank Name : ${furdexoCompany.companyBankDetails.bankName}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      worksheet.mergeCells(`K${row}:M${row}`);
      mergedCell = worksheet.getCell(`K${row}`);
      mergedCell.value = `Total`; // Set a custom title in the merged cell
      mergedCell.font = { bold: true, size: 14 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell = worksheet.getCell(`N${row}`);
      mergedCell.value = parseFloat(itemsOrderSummary.grandAmount.toFixed(2)); // Set a custom title in the merged cell
      mergedCell.font = { bold: true, size: 13 };
      mergedCell.alignment = { vertical: "middle" };
      mergedCell.numFmt = "0.00";

      row += 1;
      worksheet.mergeCells(`A${row}:J${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `A/c No. : ${furdexoCompany.companyBankDetails.accountNo}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };

      row += 1;
      worksheet.mergeCells(`A${row}:J${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `Branch & IFS Code: ${furdexoCompany.companyBankDetails.branchIFSC}`; // Set a custom title in the merged cell
      mergedCell.font = { size: 12 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };

      row += 1;
      worksheet.mergeCells(`A${row}:N${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = `Amount in Words:`; // Set a custom title in the merged cell
      mergedCell.font = { bold: true, size: 14 };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E4E8F3" },
      }; // Yellow background

      row += 1;
      worksheet.mergeCells(`A${row}:G${row}`);
      mergedCell = worksheet.getCell(`A${row}`);
      mergedCell.value = "TERMS & CONDITIONS"; // Set a custom title in the merged cell
      mergedCell.font = { size: 12, color: { argb: "FFFFFF" } };
      mergedCell.alignment = { horizontal: "left", vertical: "middle" };
      mergedCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "3B4E87" },
      }; // Yellow background

      furdexoInvoiceTC.forEach((item, index) => {
        worksheet.addRow(item);
        row += 1;
        worksheet.mergeCells(`A${row}:N${row}`);
      });

      // Write the Excel file to a buffer
      workbook.xlsx.writeBuffer().then((buffer) => {
        // Create a Blob from the buffer and trigger a download
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Invoice-${itemsOrderSummary.invoiceNumber}.xlsx`;
        link.click();
      });

      //   // Save file
      //   XLSX.writeFile(wb, `Invoice-${itemsOrderSummary.invoiceNumber}.xlsx`);
    } catch (error) {
      console.error("Error generating Excel:", error);
    } finally {
      setIsExcelGenerating(false);
    }
  };
  const [furdexoCompany] = useState({
    companyName: "COSO PROJECTS PVT, LTD.",
    companyGST: "06AAICC1977F1ZM",
    companyAddress1: "Plot No.3, Street No.3, PaliBhankri Road",
    companyAddress2: "Near Gulmohar Garden, Faridabad, Haryana",
    companyContactPhoneNo: "9625997418",
    companyEmailId: "info@furdexo.com",
    companyBankDetails: {
      accountHolder: "COSO PROJECTS PVT LTD",
      bankName: "HDFC Bank50200069761768",
      accountNo: "50200069761768",
      branchIFSC: "SCO 692 BLOCK-B, GREEN FILELDS COLONY FARIDABAD",
    },
  });

  const [invoiceItemsData, setInvoiceItemsData] = useState([]);
  useEffect(() => {
    if (!apiLoading) {
      console.log("=====invoice", invoice);

      setItemsOrderSummary((prev) => ({
        ...prev,
        invoiceNumber: invoice.isActiveInvoice
          ? invoice.invoiceNumber
          : `INV${String(invoice.newInvoice).padStart(3, "0")}`,
      }));
    }
  }, [apiLoading]);
  const fetchCustomerInvoice = async () => {
    await getCustomerInvoice(dispatch, { customerId: customerDetails._id });
  };
  useEffect(() => {
    let itemsOrder = {};
    let spaces = orderDetails.spaces || [];
    if (spaces.length > 0) {
      spaces.forEach((space) => {
        let items = space.items || [];
        items.forEach((item) => {
          console.log(item);
          if (itemsOrder[item.name]) {
            let order = itemsOrder[item.name];
            let gstAmount = item.gstAmount ? item.gstAmount : 0;
            let discountAmount = item.discountAmount ? item.discountAmount : 0;
            let grandAmount = item.grandAmount ? item.grandAmount : 0;
            console.log("===merge");
            itemsOrder[item.name] = {
              ...itemsOrder[item.name],
              quantity: parseInt(item.quantity) + parseInt(order.quantity),
              gstAmount: parseFloat(gstAmount) + parseFloat(order.gstAmount),
              discountAmount:
                parseFloat(discountAmount) + parseFloat(order.discountAmount),
              grandAmount:
                parseFloat(grandAmount) + parseFloat(order.grandAmount),
            };
          } else {
            let gstAmount = item.gstAmount ? item.gstAmount : 0;
            let discountAmount = item.discountAmount ? item.discountAmount : 0;
            let grandAmount = item.grandAmount ? item.grandAmount : 0;
            itemsOrder[item.name] = {
              name: item.name,
              unitOfMeasurement: item.unitOfMeasurement,
              ratePerUnit: item.ratePerUnit,
              hsnCode: item.hsnCode,
              gst: item.gst,
              discount: item.discount,
              categoryName: item.categoryName,
              quantity: item.quantity,
              gstAmount,
              discountAmount,
              grandAmount,
            };
          }
        });
      });
      let itemsOrderData = Object.values(itemsOrder);
      setItemsOrderSummary((prev) => ({
        ...prev,
        grandAmount: itemsOrderData.reduce(
          (sum, item) => sum + item.grandAmount,
          0
        ),
        gstAmount: itemsOrderData.reduce(
          (sum, item) => sum + item.gstAmount,
          0
        ),
      }));
      setInvoiceItemsData(itemsOrderData);
    }
    fetchCustomerInvoice();
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-[800px] flex flex-col max-h-[90vh]">
        {/* Fixed Header */}
        <div className="flex justify-between items-center p-2 border-b shrink-0">
          <h2 className="text-xl font-medium">View Invoice</h2>
          <div className="flex">
            <button
              onClick={generatePDF}
              disabled={isGenerating}
              className={`mr-2 px-4 py-2 button-decorator rounded flex items-center gap-2 ${
                isGenerating ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isGenerating ? (
                <>
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Generating PDF...
                </>
              ) : (
                <>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  Download Invoice(PDF)
                </>
              )}
            </button>
            {customerDetails.role === 1 && (
              <button
                onClick={generateExcel}
                disabled={isExcelGenerating}
                className={`px-4 py-2 button-decorator rounded hover:bg-blue-700 flex items-center gap-2 ${
                  isGenerating ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isExcelGenerating ? (
                  <>
                    <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Generating Excel...
                  </>
                ) : (
                  <>
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    Download Invoice(Excel)
                  </>
                )}
              </button>
            )}
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <div className="overflow-y-auto flex-grow bg-gray-100">
          {/* Invoice Content */}
          <div
            ref={invoiceRef}
            className="w-full max-w-5xl mx-auto bg-white p-4 shadow-lg"
          >
            {/* Header */}
            <div className="flex justify-between items-start mb-4">
              <div>
                <img
                  src={furdexo_logo}
                  alt="Furdexo Logo"
                  style={{ height: "40px" }}
                  className="mb-4"
                />
                <div className="base-text text-left">
                  <div className="font-bold">{furdexoCompany.companyName}</div>
                  <div>GSTIN: {furdexoCompany.companyGST}</div>
                  <div>{furdexoCompany.companyAddress1}</div>
                  <div>{furdexoCompany.companyAddress2}</div>
                  <div>Phone: {furdexoCompany.companyContactPhoneNo}</div>
                  <div>Email: {furdexoCompany.companyEmailId}</div>
                </div>
              </div>
              <div>
                <h1 className="text-xl font-bold text-blue-500 mb-4">
                  ESTIMATE / PRO FORMA INVOICE
                </h1>
                <div className="grid grid-cols-2 gap-4 base-text text-left">
                  <div>Date</div>
                  <div className="text-right">
                    {itemsOrderSummary.invoiceDate}
                  </div>
                  <div>Invoice #</div>
                  <div className="text-right px-2">
                    {itemsOrderSummary.invoiceNumber}
                  </div>
                  <div>Project Name</div>
                  <div className="text-right px-2">{project.projectName}</div>
                </div>
              </div>
            </div>

            {/* Customer Details */}
            <div className="mb-8">
              <div
                style={{ color: "white" }}
                className="bg-blue-700 base-text text-left text-white px-4 py-2 mb-2"
              >
                CUSTOMER
              </div>
              <div className="base-text text-left space-y-1">
                <div>
                  {customerDetails.firstName} {customerDetails.lastName}
                </div>
                <div>{customerDetails.companyName}</div>
                <div>GSTIN: {selectGSTCompany}</div>
                <div>{customerDetails.address}</div>
                <div>{customerDetails.city}</div>
                <div>{customerDetails.whatsappNo}</div>
                <div>{customerDetails.email}</div>
              </div>
            </div>

            {/* Items Table */}
            <div className="mb-8">
              <table className="w-full base-text">
                <thead>
                  <tr className="bg-blue-700 text-white">
                    <th className="py-2 px-2 text-left">S.NO.</th>
                    <th className="py-2 px-2 text-left">CATEGORY</th>
                    <th className="py-2 px-2 text-left">ITEM</th>
                    <th className="py-2 px-2 text-center">HSN CODE</th>
                    <th className="py-2 px-2 text-center">UNIT</th>
                    <th className="py-2 px-2 text-center">QTY</th>
                    <th className="py-2 px-2 text-right">UNIT PRICE</th>
                    <th className="py-2 px-2 text-center">GST</th>
                    <th className="py-2 px-2 text-center">DISCOUNT</th>
                    <th className="py-2 px-2 text-right">TOTAL AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceItemsData.map((item, index) => (
                    <tr key={item.id} className="border-b base-text text-left">
                      <td className="py-2 px-4">{index + 1}</td>
                      <td className="py-2 px-4">{item.categoryName}</td>
                      <td className="py-2 px-4">{item.name}</td>
                      <td className="py-2 px-4 text-center">{item.hsnCode}</td>
                      <td className="py-2 px-4 text-center">
                        {item.unitOfMeasurement}
                      </td>
                      <td className="py-2 px-4 text-center">{item.quantity}</td>
                      <td className="py-2 px-4 text-right">
                        {item.ratePerUnit.toFixed(2)}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {item.gstAmount.toFixed(2)}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {item.discountAmount.toFixed(2)}
                      </td>
                      <td className="py-2 px-4 text-right">
                        {item.grandAmount.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Bank Details and Totals */}
            <div className="flex justify-between">
              <div className="w-1/2">
                <div
                  style={{ color: "white" }}
                  className="bg-blue-700 text-white base-text text-left px-4 py-2 mb-2"
                >
                  BANK DETAILS
                </div>
                <div className="base-text text-left space-y-1">
                  <div>
                    A/c Holder's Name :{" "}
                    {furdexoCompany.companyBankDetails.accountHolder}
                  </div>
                  <div>
                    Bank Name : {furdexoCompany.companyBankDetails.bankName}
                  </div>
                  <div>
                    A/c No. : {furdexoCompany.companyBankDetails.accountNo}
                  </div>
                  <div>
                    Branch & IFS Code :{" "}
                    {furdexoCompany.companyBankDetails.branchIFSC}
                  </div>
                </div>
              </div>
              <div className="w-1/3">
                <table className="w-full base-text text-left">
                  <tbody>
                    <tr>
                      <td className="py-1">Subtotal</td>
                      <td className="py-1 text-right">
                        {(
                          itemsOrderSummary.grandAmount -
                          itemsOrderSummary.gstAmount
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1">Taxes</td>
                      <td className="py-1 text-right">
                        {itemsOrderSummary.gstAmount.toFixed(2)}
                      </td>
                    </tr>
                    <tr className="font-bold">
                      <td className="py-1">TOTAL</td>
                      <td className="py-1 text-right">
                        {itemsOrderSummary.grandAmount.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Terms and Conditions */}
            <div className="mt-4">
              <div
                style={{ color: "white" }}
                className="bg-blue-700 base-text text-left px-4 py-2 mb-2"
              >
                TERMS & CONDITIONS
              </div>
              <ul className="px-4 list-none text-xs">
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>Above prices are EX-Works Faridabad</span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>Order once confirmed cannot be canceled/modified</span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    Freight/transport expenses will be charged extra on actuals
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    Payment Terms - 50% Advance with Order & Balance 50% before
                    delivery
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    All QC Checks or shortages shall be reported within 24 hours
                    of supply
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    Our responsibility ceases once goods leave our premises
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    Special/Wooden Packing Charges will be extra, if required
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    We do not undertake any installations or onsite
                    work/Measurements
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    Wood work prices do not include any hardware, hinges or
                    handles etc
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    Company reserves the right to discontinue or change any
                    product specifications/price without any information
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="text-xl leading-none">•</span>
                  <span>
                    Delivery as per production schedule - approx 15 to 30 days
                    after order confirmation
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceGenerator;
