// furdexo-app-frontend/src/config/url.js
// API BASE URL --> Replace with ENV Variable
// export const API_BASE_URL = "http://127.0.0.1:5000";
export const API_BASE_URL = ""; //process.env.REACT_APP_API_BASE_URL;
console.log("======API_BASE_URL", API_BASE_URL);

//API URL
export const urlUserRegistration = `${API_BASE_URL}/api/v1/users`;
export const urlUser = `${API_BASE_URL}/api/v1/user`;
// export const urlUserDetails = `${API_BASE_URL}/api/v1/users/details`;

export const urlProjects = `${API_BASE_URL}/api/v1/projects`;
export const urlProject = `${API_BASE_URL}/api/v1/project`;
export const urlSpaces = `${API_BASE_URL}/api/v1/projects`;
export const urlChatBots = `${API_BASE_URL}/api/v1/chatbots`;

export const urlAuthLogin = `${API_BASE_URL}/api/v1/auth/login`;
export const urlAuthResetPassword = `${API_BASE_URL}/api/v1/auth/reset-password`;
export const urlAuthForgotPassword = `${API_BASE_URL}/api/v1/auth/forgot-password`;

export const urlCategories = `${API_BASE_URL}/api/v1/categories`;
export const urlCategory = `${API_BASE_URL}/api/v1/category`;
export const urlCategorySubCategoryItem = `${API_BASE_URL}/api/v1/categorysubcategoryitem`;


export const urlInvoices = `${API_BASE_URL}/api/v1/invoices`;
export const urlInvoice = `${API_BASE_URL}/api/v1/invoice`;