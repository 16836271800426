import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { ChevronDown } from "lucide-react";
import { getProjects } from "../../actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../actions/userActions";

const StatsCard = ({ title, value }) => (
  <div className="stats-card">
    <div className="base-text">{title}</div>
    <div className="small-text">{value}</div>
  </div>
);

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState({});
  const [activeProjects, setActiveProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [stats, setStats] = useState({
    totalProjects: "00",
    completedOrders: "00",
    activeOrders: "00",
  });
  const { projects, apiLoading, apiSuccess } = useSelector(
    (state) => state.project
  );
  const { users } = useSelector((state) => state.user);

  const fetchCustomers = async () => {
    await getUsers(dispatch);
  };
  console.log("======users", users);
  const fetchProjects = async () => {
    await getProjects(dispatch);
  };
  useEffect(() => {
    fetchProjects();
    fetchCustomers();
  }, []);
  useEffect(() => {
    let activeP =
      projects.filter((data) => data.currentStatus !== "Completed") || [];
    setActiveProjects(activeP);
    // if (activeP.length > 0) {
    //   setSelectedProject(activeP[0]);
    // }
    setCompletedProjects(
      projects.filter((data) => data.currentStatus === "Completed")
    );
  }, [projects]);
  useEffect(() => {
    setStats({
      totalProjects: String(projects.length).padStart(2, "0"),
      completedOrders: String(completedProjects.length).padStart(2, "0"),
      activeOrders: String(activeProjects.length).padStart(2, "0"),
    });
  }, [activeProjects, completedProjects]);
  const handleProjectClick = useCallback((customerId, projectId) => {
    setSelectedProjects((prevState) => ({
      ...prevState,
      [customerId]: projectId,
    }));
  }, []);

  const formatDate = useCallback((dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    return day + "-" + month + "-" + year;
  }, []);

  if (!apiLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-gray-600">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-red-600">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          {/* <h1 className="text-xl font-semibold text-gray-900">Furdexo</h1> */}
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-4">
          <div className="flex justify-between">
            <h2 className=" large-text mb-6">Administrator's Dashboard</h2>
          </div>

          {/* Stats Cards */}
          <div className="grid grid-cols-3 gap-6 mb-8">
            <StatsCard title="Total Projects" value={stats.totalProjects} />
            <StatsCard title="Completed Orders" value={stats.completedOrders} />
            <StatsCard title="Active Orders" value={stats.activeOrders} />
          </div>
        </div>

        {/* Table */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="min-w-full">
            {/* Table Header */}
            <div className="bg-gray-50 border-b border-gray-200 grid grid-cols-6 gap-4">
              <div className="px-4 py-3 text-left small-text uppercase tracking-wider">
                S.No.
              </div>
              <div className="px-4 py-3 text-left small-text uppercase tracking-wider">
                Company Name
              </div>
              <div className="px-4 py-3 text-left small-text uppercase tracking-wider">
                Latest Projects
              </div>
              <div className="px-4 py-3 text-left small-text uppercase tracking-wider">
                Last Updated On
              </div>
              <div className="px-4 py-3 text-left small-text uppercase tracking-wider">
                Actions
              </div>
            </div>

            {/* Table Body */}
            <div className="divide-y divide-gray-200">
              {users &&
                users?.map((customer, index) => (
                  <div
                    key={customer._id}
                    className="grid grid-cols-6 gap-4 hover:bg-gray-50"
                  >
                    {/* Customer Name */}
                    <div className="px-6 py-4 flex items-center">
                      <span className="text-gray-400 w-2 text-sm">
                        {String(index + 1).padStart(2, "0")}
                      </span>
                    </div>
                    {/* Customer Name */}
                    <div className="px-6 py-4 flex items-center">
                      <span className="text-sm text-gray-900">
                        {customer.companyName}
                      </span>
                    </div>

                    {/* Latest Projects - Clickable List */}
                    <div className="px-6 py-4">
                      <div className="flex flex-col space-y-1">
                        {projects
                          .filter(
                            (project) => project.customerId === customer._id
                          )
                          .map((project) => (
                            <div
                              key={project._id}
                              className={`text-sm px-3 py-1.5 rounded cursor-pointer ${
                                selectedProjects[customer._id] === project._id
                                  ? "bg-gray-600 text-white"
                                  : "bg-gray-100 text-gray-900"
                              }`}
                              onClick={() =>
                                handleProjectClick(customer._id, project._id)
                              }
                            >
                              {project.projectName}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Last Updated */}
                    <div className="px-6 py-4">
                      <span className="text-sm text-gray-900">
                        {formatDate(
                          projects.find(
                            (p) => p._id === selectedProjects[customer._id]
                          )?.updatedAt
                        )}
                      </span>
                    </div>

                    {/* Actions */}
                    <div className="px-4 py-4">
                      <Link
                        to={`/admin/customer/${customer._id}`}
                        className="inline-flex items-center px-3 py-1.5 button-decorator rounded shadow-sm"
                      >
                        View All Projects
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;
