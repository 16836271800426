import React from "react";

const DeleteIcon = ({ color = "#1E1E1E" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2522_21377)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.52258 2.86194C7.9144 2.47011 8.44581 2.25 8.99993 2.25C9.55405 2.25 10.0855 2.47011 10.4773 2.86194C10.7533 3.138 10.9442 3.48337 11.0328 3.85714H6.96704C7.05569 3.48337 7.24651 3.138 7.52258 2.86194ZM4.6875 3.85714C4.7963 2.88369 5.23227 1.97025 5.93159 1.27095C6.74537 0.457173 7.84907 0 8.99993 0C10.1508 0 11.2545 0.457173 12.0683 1.27095C12.7676 1.97025 13.2036 2.88369 13.3123 3.85714H16.7142C17.3355 3.85714 17.8392 4.36082 17.8392 4.98214C17.8392 5.60346 17.3355 6.10714 16.7142 6.10714H15.5892V15.9107C15.5892 16.4649 15.3691 16.9962 14.9772 17.388C14.5855 17.7799 14.0541 18 13.4999 18H4.49993C3.94581 18 3.4144 17.7799 3.02258 17.388C2.63076 16.9962 2.41064 16.4649 2.41064 15.9107V6.10714H1.28564C0.664324 6.10714 0.160645 5.60346 0.160645 4.98214C0.160645 4.36082 0.664324 3.85714 1.28564 3.85714H4.6875ZM13.9821 15.9107V6.10714H4.01779V15.9107C4.01779 16.0386 4.06859 16.1612 4.159 16.2517C4.24942 16.3421 4.37205 16.3929 4.49993 16.3929H13.4999C13.6279 16.3929 13.7504 16.3421 13.8409 16.2517C13.9313 16.1612 13.9821 16.0386 13.9821 15.9107ZM7.07136 7.71616C7.51516 7.71616 7.87493 8.07594 7.87493 8.51973V13.6646C7.87493 14.1083 7.51516 14.4681 7.07136 14.4681C6.62756 14.4681 6.26779 14.1083 6.26779 13.6646V8.51973C6.26779 8.07594 6.62756 7.71616 7.07136 7.71616ZM11.7321 8.51973C11.7321 8.07594 11.3723 7.71616 10.9285 7.71616C10.4847 7.71616 10.1249 8.07594 10.1249 8.51973V13.6646C10.1249 14.1083 10.4847 14.4681 10.9285 14.4681C11.3723 14.4681 11.7321 14.1083 11.7321 13.6646V8.51973Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2522_21377">
          <rect width="18" height="18" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteIcon;
