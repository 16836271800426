import React from "react";
import ExcelJS from "exceljs";

const ExcelCreator = () => {
  // Function to create and download an Excel file with row background color
  const createExcelFile = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define header row
    const header = ["Name", "Age", "Location", "Country"];

    // Add header to the first row of the worksheet
    worksheet.addRow(header);

    // Set background color for row 1 (Columns A to D)
    const row1 = worksheet.getRow(1);
    row1.eachCell((cell, colNumber) => {
      if (colNumber >= 1 && colNumber <= 4) {
        // Apply to columns A to D
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "4CAF50" }, // Green background color
        };
        cell.font = { bold: true, color: { argb: "FFFFFF" } }; // White text with bold font
        cell.alignment = { horizontal: "center", vertical: "middle" }; // Center text alignment
      }
    });

    // Define some data rows
    const data = [
      ["John Doe", 30, "New York", "USA"],
      ["Jane Smith", 25, "London", "UK"],
      ["Sam Brown", 22, "Sydney", "Australia"],
    ];

    // Add data rows to the worksheet
    data.forEach((row) => {
      worksheet.addRow(row);
    });

    // Set column widths for better readability
    worksheet.columns = [
      { width: 20 }, // Name column
      { width: 10 }, // Age column
      { width: 20 }, // Location column
      { width: 20 }, // Country column
    ];

    // Write the Excel file to a buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Create a Blob from the buffer and trigger a download
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "formatted_excel_with_row_bg_color.xlsx";
      link.click();
    });
  };

  return (
    <div>
      <button onClick={createExcelFile}>
        Create Excel with Row Background Color
      </button>
    </div>
  );
};

export default ExcelCreator;
