import React from "react";

const EditIcon = ({ color = "#1E1E1E" }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6764 5.23828H3.6176C3.05598 5.23828 2.51736 5.46138 2.12023 5.85851C1.7231 6.25564 1.5 6.79426 1.5 7.35588V16.8851C1.5 17.4467 1.7231 17.9853 2.12023 18.3824C2.51736 18.7796 3.05598 19.0027 3.6176 19.0027H13.1468C13.7084 19.0027 14.247 18.7796 14.6442 18.3824C15.0413 17.9853 15.2644 17.4467 15.2644 16.8851V15.8263"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.206 3.11771L17.3824 6.29411M18.8489 4.79591C19.2659 4.3789 19.5001 3.81332 19.5001 3.22359C19.5001 2.63386 19.2659 2.06828 18.8489 1.65127C18.4319 1.23427 17.8663 1 17.2765 1C16.6868 1 16.1212 1.23427 15.7042 1.65127L6.79443 10.5293V13.7057H9.97083L18.8489 4.79591Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
