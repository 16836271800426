import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Home,
  FileText,
  ChevronLeft,
  Plus,
  Trash2,
  Settings,
  Upload,
  X,
  ChevronDown,
  Search,
} from "lucide-react";
import { ArrowLeft } from "lucide-react";
import {
  deleteSpaceById,
  getSpaces,
  spaceRegistration,
  updateSpaceStatusBySpaceId,
} from "../../actions/spaceActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { urlProjects } from "../../config/url";
import { toast } from "react-toastify";
import { spaceSettingsData } from "../../shared/spaceData/materialCore";
import CurrentDateTime from "../../utils/currentDateTime";
import Processing from "../../components/Processing/Processing";
import { getCategories } from "../../actions/categoryActions";
import InvoiceGenerator from "../../shared/InvoiceGenerator/InvoiceGenerator";
import { getProjectsById } from "../../actions/projectActions";

const DoorIcon = ({ className = "" }) => (
  <svg
    className={`w-16 h-16 ${className}`}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
  >
    <path
      d="M3 3h18v18H3V3zm12 3v12M6 12h6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SpaceCard = ({ name, isSelected, onDoubleClick, onClick }) => (
  <div
    onDoubleClick={onDoubleClick}
    onClick={onClick}
    className={`rounded-lg p-6 cursor-pointer transition-all
      ${isSelected ? "bg-gray-700 text-white" : "bg-white"}
    `}
  >
    <div className="flex flex-col items-center justify-center">
      <DoorIcon className={isSelected ? "text-white" : "text-gray-700"} />
      <span className="mt-3 font-medium">{name}</span>
    </div>
  </div>
);

const DeleteModal = ({ isOpen, onClose, onDelete, itemName }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />

      {/* Modal */}
      <div className="relative bg-white rounded-lg p-6 w-full max-w-md mx-4">
        <h3 className="text-lg font-medium mb-4">
          Are you sure you want to delete {itemName}?
        </h3>

        <div className="flex gap-3">
          <button
            onClick={onDelete}
            className="flex-1 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
          >
            Yes, Delete
          </button>
          <button
            onClick={onClose}
            className="flex-1 px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200"
          >
            No, Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const SpaceDetails = ({
  space,
  fileInputRef,
  isUploading,
  spaceSettings,
  handleSpaceDelete,
  addSpaceSettingsFormattedData,
}) => (
  <div className="bg-white rounded-lg p-6">
    <div className="flex justify-between items-start">
      <div>
        <h3 className="text-lg font-medium">{space?.name}</h3>
        <p className="text-gray-600 mt-1 max-w-xl">{space?.description}</p>
      </div>
      <button
        disabled={
          space.currentStatus === "Order Placed" ||
          space.currentStatus === "Completed"
        }
        onClick={handleSpaceDelete}
        className="p-2 text-gray-500 hover:bg-gray-100 rounded-md"
      >
        <Trash2 size={18} />
      </button>
    </div>

    <div className="mt-6">
      <button
        onClick={() => fileInputRef.current?.click()}
        disabled={
          isUploading ||
          space.currentStatus === "Order Placed" ||
          space.currentStatus === "Completed"
        }
        className="flex items-center gap-2 px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800 disabled:opacity-50"
      >
        <Upload size={18} />
        {isUploading ? "Uploading..." : "Upload Drawings"}
      </button>
      <p className="text-sm text-gray-600 mt-2 text-center">
        Total uploads: {space?.uploads.length}
      </p>
    </div>

    <div className="mt-6 space-y-6">
      <div>
        <h4 className="font-medium flex items-center gap-2">
          <Settings size={18} />
          ROOM SETTINGS
        </h4>
      </div>

      <div className="space-y-6">
        {addSpaceSettingsFormattedData &&
          addSpaceSettingsFormattedData.map((data) => (
            <div>
              <h5 className="font-medium mb-3">{data.category}</h5>
              <div className="space-y-2">
                {data.subcategories.map((data1) => (
                  <>
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">{data1.label}</span>
                    </div>
                    <div className="flex flex-wrap gap-2 mb-2">
                      {data1.items &&
                        data1.items.map((material, index) => (
                          <div
                            key={index}
                            className="flex items-center bg-gray-100 rounded px-2 py-1 text-sm"
                          >
                            {material.label}
                          </div>
                        ))}
                    </div>
                  </>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>
);

const FilePreview = ({ file, version }) => (
  <div className="relative bg-white rounded-lg overflow-hidden border border-gray-200">
    <div className="absolute top-2 right-2 px-2 py-1 bg-gray-700 text-white text-xs rounded">
      {version}
    </div>
    <div className="flex flex-col items-center justify-center p-4">
      <div className="w-full h-32 flex items-center justify-center">
        <svg
          className="w-16 h-16 text-gray-400"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <path
            d="M4 4v16h16V8l-4-4H4z M8 8h8 M8 12h8 M8 16h8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="mt-2 text-center font-medium truncate w-full px-2">
        {file.name}
      </div>
    </div>
  </div>
);

const AddSpace = ({ isOpen, onClose, onSubmit, spaceSettings }) => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedSpaceSettings, setSelectedSpaceSettings] = useState({});

  const [uploadFiles, setUploadFiles] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    uploads: [],
    spaceSettings: [],
  });

  const { apiSpace, apiLoading, apiSuccess } = useSelector(
    (state) => state.space
  );

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    formData["spaceSettings"] = Object.values(selectedSpaceSettings)
      .filter((array) => array.length > 0)
      .reduce((acc, array) => acc.concat(array.map((item) => item)), []);
    formData["currentStatus"] = "Room(s) created";
    try {
      await spaceRegistration(dispatch, projectId, { ...formData });
      onClose(false);
      onSubmit();
      setFormData({
        name: "",
        description: "",
        uploads: [],
        spaceSettings: [],
      });
    } catch (error) {
      console.error("Error creating space:", error);
    }
  };

  const handleFileSelect = async (e) => {
    const files = Array.from(e.target.files);
    setUploadFiles(files);
  };
  const handleFileUpload = async (space_id) => {
    setIsUploading(true);

    try {
      const formData = new FormData();
      uploadFiles.forEach((file) => {
        formData.append("drawings", file);
      });

      const response = await fetch(
        `${urlProjects}/${projectId}/spaces/${space_id}/drawings/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      toast.success("Space Created Successfully", {
        position: "bottom-center",
      });
      setUploadedFiles((prev) => [
        ...prev,
        ...uploadFiles.map((file, index) => ({
          id: Date.now() + index,
          name: file.name,
          version: `v${prev.length + index + 1}`,
          file: file,
        })),
      ]);
    } catch (error) {
      console.error("Upload error:", error);
      // Handle error
    } finally {
      setIsUploading(false);
    }
  };
  useEffect(() => {
    console.log("====apiSpace", apiSpace);
    if (apiSpace) handleFileUpload(apiSpace.id);
  }, [apiSpace]);
  useEffect(() => {
    console.log("handleMaterialSelect===spaceSettings", spaceSettings);
    if (spaceSettings) {
      let tempSpaceSettingsData = {};
      for (let i = 0; i < spaceSettings.length; i++) {
        let spaceCategory = spaceSettings[i];
        for (let j = 0; j < spaceCategory.subcategories.length; j++) {
          let spaceSubCategory = spaceCategory.subcategories[j];
          tempSpaceSettingsData[
            `${spaceCategory._id}-${spaceSubCategory._id}`
          ] = [];
        }
      }
      setSelectedSpaceSettings(tempSpaceSettingsData);
    }
  }, [spaceSettings]);
  const [searchTerm, setSearchTerm] = useState("");

  // Handle material selection
  const handleMaterialSelect = (category, subcategoryId, material) => {
    console.log(
      "handleMaterialSelect===",
      category._id,
      subcategoryId,
      material
    );
    setSelectedSpaceSettings((prev) => ({
      ...prev,
      [`${category._id}-${subcategoryId}`]: [
        ...prev[`${category._id}-${subcategoryId}`],
        {
          ...material,
          categoryName: category.name,
          quantity: 0,
        },
      ],
    }));
  };

  // Handle material removal
  const handleMaterialRemove = (category, subcategoryId, material) => {
    setSelectedSpaceSettings((prev) => ({
      ...prev,
      [`${category._id}-${subcategoryId}`]: prev[
        `${category._id}-${subcategoryId}`
      ].filter((m) => m._id !== material._id),
    }));
  };

  const filterSpaceSettings = (category, subcategory, searchTerm) => {
    if (!searchTerm) {
      return subcategory.items || [];
    }

    const filtered = subcategory.items.filter(
      (data) => data.label[0].toLowerCase() === searchTerm[0].toLowerCase()
    );
    return filtered;
  };

  // Material Selection Dropdown Component
  const MaterialDropdown = ({ category, subcategory }) => {
    // debugger;
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null); // Ref for the dropdown
    const inputRef = useRef(null); // Ref for the input field

    // useEffect for detecting click outside to close the dropdown
    useEffect(() => {
      // Close the dropdown if click is outside the dropdown
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current && // Ensure we have a reference to the dropdown
          !dropdownRef.current.contains(event.target) && // Check if click is outside
          !inputRef.current.contains(event.target) // Check if click is outside the input
        ) {
          setIsOpen(false); // Close dropdown
        }
      };

      // Add event listener
      document.addEventListener("mousedown", handleClickOutside);

      // Cleanup event listener on unmount
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    return (
      <div className="relative mb-4">
        <label className="text-sm font-medium text-gray-700 mb-1 block">
          {subcategory.name}
        </label>
        <div className="flex flex-wrap gap-2 mb-2">
          {selectedSpaceSettings[`${category._id}-${subcategory._id}`] &&
            selectedSpaceSettings[`${category._id}-${subcategory._id}`].map(
              (material, index) => (
                <div
                  key={index}
                  className="flex items-center bg-gray-100 rounded px-2 py-1 text-sm"
                >
                  {material.name}
                  <button
                    onClick={() =>
                      handleMaterialRemove(category, subcategory._id, material)
                    }
                    className="ml-2"
                  >
                    <X className="w-4 h-4 text-gray-500" />
                  </button>
                </div>
              )
            )}
        </div>
        <div className="relative">
          <input
            ref={inputRef}
            type="text"
            className="w-full p-2 border rounded-md"
            placeholder="Search tags"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsOpen(true)}
            // onBlur={() => setIsOpen(false)}
          />
          <Search className="absolute right-2 top-2.5 w-5 h-5 text-gray-400" />
        </div>
        {isOpen && (
          <div
            ref={dropdownRef} // Attach ref to the dropdown container
            className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto"
          >
            <div className="p-2">
              <div className="text-sm font-medium text-gray-700 mb-2">
                CURRENT SELECTIONS
              </div>
              {selectedSpaceSettings[`${category._id}-${subcategory._id}`] &&
                selectedSpaceSettings[`${category._id}-${subcategory._id}`].map(
                  (material, index) => (
                    <div
                      key={index}
                      className="bg-gray-600 text-white p-2 mb-1 rounded text-sm"
                    >
                      {material.name}
                    </div>
                  )
                )}
            </div>

            {filterSpaceSettings(category, subcategory, searchTerm).map(
              (insubcategory, index) => (
                <div
                  key={index}
                  className="px-3 py-2 hover:bg-gray-50 cursor-pointer text-sm"
                  onClick={() => {
                    handleMaterialSelect(
                      category,
                      subcategory._id,
                      insubcategory
                    );
                    setSearchTerm("");
                  }}
                >
                  {insubcategory.name}
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  };
  console.log("====selectedSpaceSettings", selectedSpaceSettings);
  console.log("=====spaceSettings", spaceSettings);
  if (!isOpen) return null;
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white rounded-lg w-[800px] flex flex-col max-h-[90vh]">
          {/* Fixed Header */}
          <div className="flex justify-between items-center p-6 border-b shrink-0">
            <h2 className="text-xl font-medium">Add New Room</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Scrollable Content */}
          <div className="overflow-y-auto flex-grow">
            <div className="p-6">
              <div className="grid grid-cols-2 gap-8">
                {/* Left Column */}
                <div className="space-y-4">
                  <input
                    type="text"
                    placeholder="Name"
                    value={formData.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                    className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                  />

                  <textarea
                    placeholder="Description"
                    value={formData.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md h-32"
                  />

                  <div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                      multiple
                      accept=".pdf,.dwg,.dxf"
                      className="hidden"
                    />

                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <button
                          onClick={() => fileInputRef.current?.click()}
                          disabled={isUploading}
                          className="flex items-center gap-2 px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800 disabled:opacity-50"
                        >
                          <Upload size={18} />
                          {isUploading ? "Uploading..." : "Upload Drawings"}
                        </button>
                        <span className="text-sm text-gray-600">
                          Total uploads{" "}
                          {uploadFiles.length.toString().padStart(2, "0")}
                        </span>
                      </div>

                      {uploadFiles.length > 0 && (
                        <div className="grid grid-cols-2 gap-3 bg-gray-100 p-3 rounded-md">
                          {uploadFiles.map((file, index) => (
                            <FilePreview
                              key={file.id}
                              file={file}
                              version={file.version}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Right Column */}
                <div>
                  <h3 className="text-lg font-medium mb-4">
                    Default Room Settings
                  </h3>

                  {spaceSettings.map((spaceSetting) => (
                    <>
                      <div className="mb-6">
                        <h4 className="text-sm font-medium mb-3">
                          {spaceSetting?.name}
                        </h4>
                        {spaceSetting.subcategories.map((subcategory) => (
                          <MaterialDropdown
                            category={spaceSetting}
                            subcategory={subcategory}
                          />
                        ))}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Fixed Footer */}
          <div className="border-t p-6 flex justify-end shrink-0">
            <button
              onClick={handleSubmit}
              className="bg-gray-700 text-white px-6 py-2 rounded text-sm"
            >
              Add New Room
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const EstimateTable = ({
  onClose,
  spaces,
  totalEstimate,
  totalGSTAmount,
  projectDetails,
}) => {
  const { user } = useSelector((state) => state.user);
  const [isInvoiceGeneratorModel, setIsInvoiceGeneratorModel] = useState(false);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          {/* Header */}
          <div className="flex justify-between items-start mb-6">
            <div className="flex items-center gap-3">
              <div className="flex justify-between items-start">
                <FileText className="text-gray-700" size={24} />
                <div className="text-lg font-medium">PROJECT ESTIMATE</div>
                {/* <div className="text-2xl font-semibold flex items-center gap-1">
                  Total Estimate <span className="text-sm">₹</span>
                  {totalEstimate.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                  })}
                </div> */}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex justify-between items-start">
                <button
                  onClick={() => {
                    setIsInvoiceGeneratorModel(!isInvoiceGeneratorModel);
                  }}
                  className="w-full mr-2 bg-gray-700 text-white px-4 py-2 rounded"
                >
                  View Invoice
                </button>
              </div>
            </div>
            <div className="flex justify-between items-start mb-6">
              <div className="grid grid-cols-2">
                <span className="text-gray-600 text-left">Grand Total</span>
                <div className="flex items-baseline justify-end">
                  <span className="text-sm align-top">₹</span>
                  <span className="text-2xl font-medium">
                    {totalEstimate.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <span className="text-gray-600 text-left">
                  Total Items Estimate
                </span>
                <div className="flex items-baseline justify-end">
                  <span className="text-sm align-top">₹</span>
                  <span className="text-lg">
                    {(totalEstimate - totalGSTAmount).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <span className="text-gray-600 text-left">Total GST</span>
                <div className="flex items-baseline justify-end">
                  <span className="text-sm align-top">₹</span>
                  <span className="text-lg">
                    {totalGSTAmount.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <X size={20} className="text-gray-500" />
              </button>
            </div>
          </div>

          {/* Table */}
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="py-3 px-4 text-left font-medium">CATEGORY</th>
                <th className="py-3 px-4 text-left font-medium">DETAIL</th>
                <th className="py-3 px-4 text-left font-medium">
                  UNIT OF MEASUREMENT
                </th>
                <th className="py-3 px-4 text-right font-medium">QUANTITY</th>
                <th className="py-3 px-4 text-right font-medium">
                  RATE / UNIT
                </th>
                <th className="py-3 px-4 text-right font-medium">GST %</th>
                <th className="py-3 px-4 text-right font-medium">Discount %</th>
                <th className="py-3 px-4 text-right font-medium">PRICE</th>
              </tr>
            </thead>
            <tbody>
              {spaces.map((space, spaceIndex) => (
                <React.Fragment key={space.name}>
                  {/* Space Header */}
                  <tr className="bg-gray-50">
                    <td
                      colSpan={2}
                      className="py-3 px-4 font-medium"
                      style={{ textAlign: "center" }}
                    >
                      {space.currentStatus === "Order Placed"
                        ? "Order Placed"
                        : ""}
                    </td>
                    <td
                      colSpan={5}
                      className="py-3 px-4 font-medium"
                      style={{ textAlign: "left" }}
                    >
                      {space.name}
                    </td>
                    <td className="py-3 px-4 text-right">
                      <div className="text-sm text-gray-600">Room Estimate</div>
                      <div className="font-medium flex items-center justify-end gap-1">
                        <span className="text-sm">₹</span>
                        {space.estimate.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </td>
                  </tr>

                  {/* Space Items */}
                  {space.items.map((item, itemIndex) => (
                    <tr
                      key={`${spaceIndex}-${itemIndex}`}
                      className={
                        itemIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }
                    >
                      <td className="py-3 px-4">{item.categoryName}</td>
                      <td className="py-3 px-4">{item.name}</td>
                      <td className="py-3 px-4">{item.unitOfMeasurement}</td>
                      <td className="py-3 px-4 text-right">
                        {item.quantity.toString().padStart(2, "0")}
                      </td>
                      <td className="py-3 px-4 text-right">
                        ₹
                        {item?.ratePerUnit
                          ? item.ratePerUnit.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                      <td className="py-3 px-4 text-right">
                        {item.gst.toString().padStart(2, "0")}
                      </td>
                      <td className="py-3 px-4 text-right">
                        {item?.discount
                          ? item.discount.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                      <td className="py-3 px-4 text-right">
                        ₹
                        {item?.grandAmount
                          ? item.grandAmount.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isInvoiceGeneratorModel && (
        <InvoiceGenerator
          isOpen={isInvoiceGeneratorModel}
          onClose={() => setIsInvoiceGeneratorModel(false)}
          orderDetails={{ spaces: spaces }}
          customerDetails={user}
          // selectGSTCompany={gstCompany}
          project={projectDetails}
        />
      )}
    </div>
  );
};

const ProjectEstimate = ({
  isOpen,
  onClose,
  onSubmit,
  spaces,
  projectDetails,
}) => {
  const calculateRoomEstimate = useCallback((items) => {
    return items.reduce(
      (sum, item) => sum + (item.grandAmount ? item.grandAmount : 0),
      0
    );
  }, []);
  const calculateRoomGSTAmount = useCallback((items) => {
    return items.reduce(
      (sum, item) => sum + (item.gstAmount ? item.gstAmount : 0),
      0
    );
  }, []);
  if (!isOpen) return null;
  console.log("====spaces===ProjectEstimate==spaces", spaces);
  var spacesData = spaces.map((data) => ({
    name: data.name,
    currentStatus: data.currentStatus,
    estimate: calculateRoomEstimate(data.spaceSettings),
    gstAmount: calculateRoomGSTAmount(data.spaceSettings),
    items: data.spaceSettings,
  }));
  console.log("====spaces===ProjectEstimate==spacesData", spacesData);
  const estimateData = {
    totalEstimate: spacesData.reduce((sum, room) => sum + room.estimate, 0),
    totalGSTAmount: spacesData.reduce((sum, room) => sum + room.gstAmount, 0),
    spaces: spacesData,
  };
  console.log("====spaces===ProjectEstimate", estimateData);
  return (
    <EstimateTable
      onClose={onClose}
      {...estimateData}
      projectDetails={projectDetails}
    />
  );
};

const ProjectDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const [selectedSpace, setSelectedSpace] = useState(0);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEstimateModalOpen, setIsEstimateModalOpen] = useState(false);
  const [spaceSettings, setSpaceSettings] = useState([]);
  const [spaceSettingsFormattedData, setSpaceSettingsFormattedData] = useState(
    []
  );
  const [addSpaceSettingsFormattedData, setAddSpaceSettingsFormattedData] =
    useState([]);
  const { spaces, apiLoading, apiSuccess } = useSelector(
    (state) => state.space
  );
  const { categories, apiLoading: apiLoadingCategories } = useSelector(
    (state) => state.category
  );
  const {
    projects,
    project,
    apiLoading: apiProjectLoading,
  } = useSelector((state) => state.project);
  const handleGetSpace = async () => {
    await getSpaces(dispatch, projectId);
  };
  const fetchProjectById = async () => {
    await getProjectsById(dispatch, projectId);
  };
  useEffect(() => {
    handleGetSpace();
    fetchProjectById();
  }, [dispatch, projectId]);

  const handleGetCategories = async () => {
    await getCategories(dispatch, {});
  };

  useEffect(() => {
    handleGetCategories();
  }, []);

  useEffect(() => {
    if (!apiProjectLoading) {
      setProjectDetails(project);
    }
  }, [apiProjectLoading]);

  useEffect(() => {
    console.log(
      "====1111==spaceSettingsData",
      apiLoadingCategories,
      categories
    );
    if (!apiLoadingCategories) {
      setSpaceSettings(categories);
      const tempSpaceSettingsData = categories.reduce((acc, spaceSetting) => {
        // Check if the category exists, if not, create it
        if (!acc.some((item) => item.category === spaceSetting.category)) {
          acc.push({
            category: spaceSetting.category,
            subcategories: [],
          });
        }

        // Find the category object
        const categoryObj = acc.find(
          (item) => item.category === spaceSetting.category
        );

        // Check if the subcategory exists for this category, if not, create it
        if (
          !categoryObj.subcategories.some(
            (sub) => sub.label === spaceSetting.subcategory
          )
        ) {
          categoryObj.subcategories.push({
            label: spaceSetting.subcategory,
            items: [],
          });
        }

        // Find the subcategory object
        const subcategoryObj = categoryObj.subcategories.find(
          (sub) => sub.label === spaceSetting.subcategory
        );

        // Push the product to the correct subcategory
        subcategoryObj.items.push({
          _id: spaceSetting._id,
          category: spaceSetting.category,
          subcategory: spaceSetting.subcategory,
          label: spaceSetting.label,
          rate: spaceSetting?.rate,
          quantity: 0,
          price: 0,
          unit: "Running ft.",
        });

        return acc; // Return the accumulator for the next iteration
      }, []);
      console.log("=====tempSpaceSettingsData", tempSpaceSettingsData);
      setSpaceSettingsFormattedData(tempSpaceSettingsData);
    }
  }, [apiLoadingCategories]);

  useEffect(() => {
    let selectedSpaceDetails = spaces.find(
      (space, index) => selectedSpace === index
    );
    console.log("==selectedSpace===selectedSpaceDetails", selectedSpaceDetails);
    const addedSpaceSettingsData = selectedSpaceDetails?.spaceSettings || [];
    console.log("addedSpaceSettingsData", addedSpaceSettingsData);
    const tempAddSpaceSettingsData = addedSpaceSettingsData.reduce(
      (acc, spaceSetting) => {
        // Check if the category exists, if not, create it
        if (!acc.some((item) => item.category === spaceSetting.category)) {
          acc.push({
            category: spaceSetting.category,
            subcategories: [],
          });
        }

        // Find the category object
        const categoryObj = acc.find(
          (item) => item.category === spaceSetting.category
        );

        // Check if the subcategory exists for this category, if not, create it
        if (
          !categoryObj.subcategories.some(
            (sub) => sub.label === spaceSetting.subcategory
          )
        ) {
          categoryObj.subcategories.push({
            label: spaceSetting.subcategory,
            items: [],
          });
        }

        // Find the subcategory object
        const subcategoryObj = categoryObj.subcategories.find(
          (sub) => sub.label === spaceSetting.subcategory
        );

        // Push the product to the correct subcategory
        subcategoryObj.items.push({
          _id: spaceSetting._id,
          label: spaceSetting.label,
          rate: spaceSetting?.rate,
          category: spaceSetting.category,
          subcategory: spaceSetting.subcategory,
          quantity: 0,
          price: 0,
          unit: "Running ft.",
        });

        return acc; // Return the accumulator for the next iteration
      },
      []
    );
    setAddSpaceSettingsFormattedData(tempAddSpaceSettingsData);
  }, [spaces, selectedSpace, spaceSettingsData]);
  console.log("spaces, apiLoading", addSpaceSettingsFormattedData);

  const handleFileSelect = async (e) => {
    const files = Array.from(e.target.files);
    setIsUploading(true);

    let selectedSpaceDetails = spaces.find(
      (space, index) => selectedSpace === index
    );
    console.log("=====selectedSpaceDetails", selectedSpaceDetails);
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("drawings", file);
      });

      const response = await fetch(
        `${urlProjects}/${projectId}/spaces/${selectedSpaceDetails._id}/drawings/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      toast.success(data?.message, { position: "bottom-center" });
      setUploadedFiles((prev) => [
        ...prev,
        ...files.map((file, index) => ({
          id: Date.now() + index,
          name: file.name,
          version: `v${prev.length + index + 1}`,
          file: file,
        })),
      ]);
      handleGetSpace();
    } catch (error) {
      console.error("Upload error:", error);
      // Handle error
    } finally {
      setIsUploading(false);
    }
  };

  const handleSpaceDelete = async () => {
    console.log("====handleSpaceDelete");
    setIsDeleteModalOpen(false);
    setShowProcessing(true);
    await deleteSpaceById(dispatch, projectId, spaces[selectedSpace]._id);
    handleGetSpace();
    setShowProcessing(false);
  };

  const handlePlaceOrder = async (spaceId) => {
    console.log("====handlePlaceOrder", spaceId);
    setShowProcessing(true);
    await updateSpaceStatusBySpaceId(dispatch, projectId, spaceId, {
      currentStatus: "Order Placed",
    });
    handleGetSpace();
    setShowProcessing(false);
  };
  // console.log("======projectDetails", projectDetails);
  const [currentStatus, setCurrentStatus] = useState(3);

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        {/* Main Content */}
        <main className="max-w-7xl mx-auto px-4 py-6">
          <div className="mb-6 flex flex-wrap gap-4 justify-between items-center">
            <h2 className="text-2xl font-medium">
              {projectDetails?.projectName}
            </h2>
            <div className="flex gap-3">
              {spaces.length > 0 && (
                <button
                  onClick={() => setIsEstimateModalOpen(true)}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800"
                >
                  <FileText size={18} />
                  View Project Estimate
                </button>
              )}
              {/* <button
                onClick={() => navigate(`/customer/enquiries`)}
                className="flex items-center gap-2 px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800"
              >
                <ChevronLeft size={18} />
                Back
              </button> */}
              <button
                onClick={() => setIsCreateModalOpen(true)}
                className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                <Plus size={18} />
                Add New Room
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            <div className="lg:col-span-3">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {!apiLoading &&
                  spaces.map((space, index) => (
                    <SpaceCard
                      key={index}
                      name={space.name}
                      isSelected={selectedSpace === index}
                      onDoubleClick={() => {
                        navigate(
                          `/customer/project/${projectId}/${space._id}/drawings`
                        );
                      }}
                      onClick={() => {
                        setSelectedSpace(index);
                      }}
                    />
                  ))}
                <SpaceCard
                  key={0}
                  name={"Uploaded by Furdexo"}
                  onClick={() => {
                    navigate(`/customer/project/${projectId}/furdexo-uploads`);
                  }}
                />
              </div>
              {!apiLoading && spaces[selectedSpace] && (
                <>
                  <div className="mt-8 bg-white p-6 rounded">
                    <div className="flex justify-between items-center mb-6">
                      <h3 className="font-bold">
                        {spaces[selectedSpace]?.name}
                      </h3>
                      <button
                        disabled={
                          spaces[selectedSpace].currentStatus === "Order Placed"
                        }
                        onClick={() =>
                          handlePlaceOrder(spaces[selectedSpace]?._id)
                        }
                        className="bg-gray-700 text-white px-4 py-2 rounded"
                      >
                        Place Order
                      </button>
                    </div>

                    <div className="mb-4">TIMELINE</div>
                    <div className="relative">
                      {spaces[selectedSpace]?.spaceStatus && (
                        <>
                          <div className="h-1 bg-gray-300 absolute w-full top-4"></div>
                          <div className="flex justify-between relative">
                            {spaces[selectedSpace]?.spaceStatus.map(
                              (point, index) => (
                                <div key={point.id} className="text-center">
                                  <div
                                    className={`w-8 h-8 rounded-full ${
                                      index < currentStatus
                                        ? "bg-gray-700 text-white"
                                        : "bg-gray-300"
                                    } flex items-center justify-center mb-2`}
                                  >
                                    {index}
                                  </div>
                                  <div className="text-sm">{point.status}</div>
                                </div>
                              )
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            {!apiLoading && spaces[selectedSpace] && (
              <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  multiple
                  accept=".pdf,.dwg,.dxf"
                  className="hidden"
                />
                <SpaceDetails
                  space={spaces[selectedSpace]}
                  fileInputRef={fileInputRef}
                  isUploading={isUploading}
                  spaceSettings={spaceSettings}
                  handleSpaceDelete={() => setIsDeleteModalOpen(true)}
                  addSpaceSettingsFormattedData={addSpaceSettingsFormattedData}
                />
              </div>
            )}
          </div>
        </main>
        <AddSpace
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          onSubmit={handleGetSpace}
          spaceSettings={spaceSettings}
        />
        <ProjectEstimate
          isOpen={isEstimateModalOpen}
          onClose={() => setIsEstimateModalOpen(false)}
          onSubmit={handleGetSpace}
          spaces={spaces}
          projectDetails={projectDetails}
        />
      </div>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleSpaceDelete}
        itemName={spaces[selectedSpace]?.name}
      />
      <Processing show={showProcessing} />
    </>
  );
};

export default ProjectDetails;
