// furdexo-app-frontend/src/action/userActions.js
import axios from "axios";
import {
  urlAuthForgotPassword,
  urlAuthLogin,
  urlAuthResetPassword,
  urlUserRegistration,
  urlUser,
  urlCategories,
  urlCategory,
  urlCategorySubCategoryItem,
} from "../config/url";

export async function getCategories(dispatch, payload) {
  try {
    dispatch({ type: "REQ_CATEGORY", apiResponse: {} });
    const response = await axios.get(urlCategories, payload);
    dispatch({ type: "FETCH_CATEGORIES", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "CATEGORY_ERROR", apiResponse: error });
  }
}

export async function addCategory(dispatch, payload) {
  try {
    dispatch({ type: "REQ_CATEGORY", apiResponse: {} });
    const response = await axios.post(urlCategories, payload);
    dispatch({ type: "ADD_CATEGORY", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "CATEGORY_ERROR", apiResponse: error });
  }
}

export async function addSubCategory(dispatch, categoryId, payload) {
  try {
    dispatch({ type: "REQ_CATEGORY", apiResponse: {} });
    const response = await axios.post(
      `${urlCategory}/${categoryId}/subcategories`,
      payload
    );
    dispatch({ type: "ADD_SUBCATEGORY", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "CATEGORY_ERROR", apiResponse: error });
  }
}

export async function addSubCategoryItem(dispatch, categoryId, subCategoryId, payload) {
  try {
    dispatch({ type: "REQ_CATEGORY", apiResponse: {} });
    const response = await axios.post(
      `${urlCategory}/${categoryId}/subcategory/${subCategoryId}/items`,
      payload
    );
    dispatch({ type: "ADD_SUBCATEGORY_ITEM", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "CATEGORY_ERROR", apiResponse: error });
  }
}


export async function updateSubCategoryItem(
  dispatch,
  categoryId,
  subCategoryId,
  itemId,
  payload
) {
  try {
    dispatch({ type: "REQ_CATEGORY", apiResponse: {} });
    const response = await axios.put(
      `${urlCategory}/${categoryId}/subcategory/${subCategoryId}/item/${itemId}`,
      payload
    );
    dispatch({ type: "UPDATE_SUBCATEGORY_ITEM", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "CATEGORY_ERROR", apiResponse: error });
  }
}

export async function deleteSubCategoryItem(
  dispatch,
  categoryId,
  subCategoryId,
  itemId
) {
  try {
    dispatch({ type: "REQ_CATEGORY", apiResponse: {} });
    const response = await axios.delete(
      `${urlCategory}/${categoryId}/subcategory/${subCategoryId}/item/${itemId}`
    );
    dispatch({ type: "DELETE_SUBCATEGORY_ITEM", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "CATEGORY_ERROR", apiResponse: error });
  }
}

export async function addCategorySubCategoryItem(dispatch, payload) {
  try {
    dispatch({ type: "REQ_CATEGORY", apiResponse: {} });
    const response = await axios.post(urlCategorySubCategoryItem, payload);
    dispatch({ type: "ADD_CATEGORY_SUBCATEGORY_ITEM", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "CATEGORY_ERROR", apiResponse: error });
  }
}