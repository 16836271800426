// furdexo-app-frontend/src/pages/customer/CustomerRegistration.js
import React, { useState, useEffect } from "react";
import { userRegistration } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import furdexo_logo from '../../static/images/furdexo-logo.png'

const CustomerRegistration = () => {
  const dispatch = useDispatch();
    const { apiLoading, apiResponse, apiSuccess } = useSelector(
      (state) => state.user
    );
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      companyName: "",
      websiteLink: "",
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      email: "",
      password: "",
      whatsappNo: "",
      termsAccepted: false,
    });
  
    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log("Form submitted:", formData);
      await userRegistration(dispatch, { ...formData });
    };
    useEffect(() => {
      if (!apiLoading && apiSuccess) {
        navigate(`/login`);
      }
    }, [navigate, apiLoading]);

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="text-center mb-8">
        <h1 className="text-2xl font-semibold mb-2">Welcome to</h1>
        <img src={furdexo_logo} alt="Furdexo Logo" className="mx-auto mb-4" />
        <a href="/login" className="text-purple-700 hover:underline">
          Sign in to your account
        </a>
      </div>

      <div className="bg-white rounded-lg shadow-sm border p-8">
        <h2 className="text-xl font-semibold mb-6">Create Profile</h2>

        <form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="flex-1 border-r pr-8">
              <h3 className="text-lg font-medium mb-4">Company Details</h3>
              <div className="space-y-4">
                <input
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.companyName}
                />
                <input
                  type="url"
                  name="websiteLink"
                  placeholder="Website Link"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.websiteLink}
                />
              </div>
            </div>

            <div className="flex-1 pl-8">
              <h3 className="text-lg font-medium mb-4">Your Account</h3>
              <div className="space-y-4">
                <div className="flex gap-4">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className="w-1/2 p-3 rounded-md bg-gray-50"
                    onChange={handleInputChange}
                    value={formData.firstName}
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className="w-1/2 p-3 rounded-md bg-gray-50"
                    onChange={handleInputChange}
                    value={formData.lastName}
                  />
                </div>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.address}
                />
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.city}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.email}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.password}
                />
                <div className="flex">
                  <span className="bg-gray-50 p-3 rounded-l-md text-gray-500">
                    +91
                  </span>
                  <input
                    type="tel"
                    name="whatsappNo"
                    placeholder="Whatsapp No."
                    className="flex-1 p-3 rounded-r-md bg-gray-50"
                    onChange={handleInputChange}
                    value={formData.whatsappNo}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-center">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="termsAccepted"
                className="rounded"
                onChange={handleInputChange}
                checked={formData.termsAccepted}
              />
              <span className="text-sm">
                I agree to the Terms of Service. Read them{" "}
                <a href="#" className="text-purple-700 hover:underline">
                  here
                </a>
              </span>
            </label>
          </div>

          <div className="mt-6 flex justify-center">
            <button
              type="submit"
              className="px-6 py-3 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
            >
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerRegistration;
