// furdexo-app-frontend/src/pages/customer/ProfileSettings.js

import React, { useEffect, useState } from "react";
import { X } from "lucide-react";
import { getUserDetails, updateUser } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../config/url";

const ProfileSettings = ({ id, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(null);
  const { user } = useSelector((state) => state.user);

  const showMessage = (text, type = "success") => {
    setMessage({ text, type });
    setTimeout(() => setMessage(null), 3000);
  };

  const validateForm = () => {
    const newErrors = {};

    // Company validations
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company name is required";
    }

    // Profile validations
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (!validateForm()) {
      showMessage("Please fix the errors before submitting", "error");
      return;
    }

    setIsSubmitting(true);
    try {
      const submitData = new FormData();
      Object.keys(formData).forEach((key) => {
        if (
          [
            "companyName",
            "websiteLink",
            "firstName",
            "lastName",
            "address",
            "city",
            "email",
            "whatsappNo",
            "imageFile",
            "companyLogoFile",
          ].includes(key)
        ) {
          submitData.append(key, formData[key]);
        }
      });
      var gst = [];
      if (formData["gst1"]) {
        gst.push({ gstNumber: formData["gst1"] });
      }
      if (formData["gst2"]) {
        gst.push({ gstNumber: formData["gst2"] });
      }
      if (formData["gst3"]) {
        gst.push({ gstNumber: formData["gst3"] });
      }
      submitData.append("companyGst", JSON.stringify(gst));
      await updateUser(dispatch, formData._id, submitData);
      onClose(false);
    } catch (error) {
      console.log("===error", error);
      showMessage("Failed to update profile", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prev) => ({
          ...prev,
          profileImage: reader.result,
          imageFile: file, // Store the file for later upload
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCompanyLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prev) => ({
          ...prev,
          companyLogoImage: reader.result,
          companyLogoFile: file, // Store the file for later upload
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchUserDetails = async () => {
    await getUserDetails(dispatch, id);
  };

  useEffect(() => {
    if (id) fetchUserDetails();
  }, [id]);

  useEffect(() => {
    if (user) {
      var userData = {...user};
      if (user.companyGst){
        user.companyGst.forEach((element,idx)=>{
          var gstKey = `gst${idx+1}`;
          userData[gstKey]= element["gstNumber"];
        })
      } 
      setFormData(userData);
    }
  }, [user]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-100 rounded-lg p-6 w-full max-w-5xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-medium">Profile Settings</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="flex-1 border-r pr-8">
              <h3 className="text-lg font-medium mb-4">Company Details</h3>
              <div className="space-y-4">
                <input
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.companyName}
                />
                <input
                  type="url"
                  name="websiteLink"
                  placeholder="Website Link"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.websiteLink}
                />
              </div>
              <div className="mb-4 mt-4">
                <div className="text-center flex bg-gray-50 p-3 rounded-md">
                  <div style={{ marginTop: "10px" }}>
                    <label className="cursor-pointer">
                      <span className="text-sm text-gray-700 hover:text-gray-900">
                        Upload Company Logo
                      </span>
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={handleCompanyLogoUpload}
                      />
                    </label>
                  </div>
                  <div
                    style={{ marginLeft: "10px" }}
                    className="w-12 h-12 bg-gray-200 rounded-full"
                  >
                    {formData.companyLogoImage || formData.companyLogoFile ? (
                      <img
                        src={
                          formData.companyLogoImage ||
                          `${API_BASE_URL}/${formData.companyLogoFile}`
                        }
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-3xl">👤</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <input
                  type="text"
                  name="gst1"
                  placeholder="GST 1"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.gst1}
                />
                <input
                  type="text"
                  name="gst2"
                  placeholder="GST 2"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.gst2}
                />
                <input
                  type="text"
                  name="gst3"
                  placeholder="GST 3"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.gst3}
                />
              </div>
            </div>

            <div className="flex-1 pl-8">
              <h3 className="text-lg font-medium mb-4">Your Account</h3>
              <div className="space-y-4">
                <div className="flex gap-4">
                  <div>
                    <div>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        className="p-3 rounded-md bg-gray-50"
                        onChange={handleInputChange}
                        value={formData.firstName}
                      />
                    </div>
                    <div style={{ marginTop: "5px" }}>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        className="p-3 rounded-md bg-gray-50"
                        onChange={handleInputChange}
                        value={formData.lastName}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="text-center flex bg-gray-50 p-3 rounded-md">
                      <div style={{ marginTop: "10px" }}>
                        <label className="cursor-pointer">
                          <span className="text-sm text-gray-700 hover:text-gray-900">
                            Upload Display Profile
                          </span>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </label>

                        {/* <span className="text-sm text-gray-700">
                          Upload Display Profile
                        </span>
                        <input
                          type="file"
                          className="hidden"
                          accept="image/*"
                          onChange={handleImageUpload}
                        /> */}
                      </div>
                      <div
                        style={{ marginLeft: "10px" }}
                        className="w-12 h-12 bg-gray-200 rounded-full"
                      >
                        {formData.profileImage || formData.imageFile ? (
                          <img
                            src={
                              formData.profileImage ||
                              `${API_BASE_URL}/${formData.imageFile}`
                            }
                            alt="Profile"
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <span className="text-3xl">👤</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.address}
                />
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.city}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full p-3 rounded-md bg-gray-50"
                  onChange={handleInputChange}
                  value={formData.email}
                />
                <div className="flex">
                  <span className="bg-gray-50 p-3 rounded-l-md text-gray-500">
                    +91
                  </span>
                  <input
                    type="tel"
                    name="whatsappNo"
                    placeholder="Whatsapp No."
                    className="flex-1 p-3 rounded-r-md bg-gray-50"
                    onChange={handleInputChange}
                    value={formData.whatsappNo}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-center">
            <button
              type="submit"
              className="px-6 py-3 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
            >
              Save Settings
            </button>
          </div>
        </form>

        {message && (
          <div
            className={`fixed bottom-4 right-4 px-4 py-2 rounded-lg text-white
            ${message.type === "success" ? "bg-green-600" : "bg-red-600"}`}
          >
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSettings;
