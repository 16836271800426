// client/src/components/CustomerInfo.js

import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ChevronDown, History, FileText, Download } from "lucide-react";
import {
  getProjectsByCustomerId,
  updateProjectsByProjectId,
} from "../../actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import OrderHistory from "./OrderHistory";
import { getUserDetails } from "../../actions/userActions";
import UploadFiles from "./UploadFiles";
import { updateSpaceStatusBySpaceId } from "../../actions/spaceActions";

const ActionButton = ({ icon: Icon, label, onClick, variant = "light" }) => (
  <button
    onClick={onClick}
    className={`inline-flex items-center justify-center space-x-2 px-4 py-2 rounded button-decorator`}
  >
    {Icon && <Icon size={16} className="mr-2" />}
    <span>{label}</span>
  </button>
);

const StatsIndicator = ({ label, value }) => (
  <div className="flex items-center space-x-2">
    <span className="base-text">{label}</span>
    <span className="small-text">{value}</span>
  </div>
);

const UpdateStatusDropdown = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    // "Revised Cut Sheet Drawings Provided",
    "Value engineering",
    "Advance receipt",
    "Procurement",
    "Production",
    "Packing",
    "Ready for dispatch",
    "Payment",
    "Dispatched",
    "Project Hold",
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <span className="truncate">{value}</span>
        <ChevronDown
          className={`ml-2 h-4 w-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
          <div className="absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
            {options.map((option, index) => (
              <button
                key={index}
                className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100
                  ${value === option ? "bg-gray-100" : ""}`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const CustomerInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const [activeProjects, setActiveProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [showOrderHistory, setShowOrderHistory] = useState(false);
  const [showUploadFiles, setShowUploadFiles] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [updateProjectStatus, setUpdateProjectStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalProjects: "00",
    projectsCompleted: "00",
  });

  const { projects, apiLoading, apiSuccess } = useSelector(
    (state) => state.project
  );

  const { user } = useSelector((state) => state.user);
  console.log("=====user", user);
  const fetchProjects = async () => {
    await getProjectsByCustomerId(dispatch, customerId);
  };

  const fetchUserDetails = async () => {
    await getUserDetails(dispatch, customerId);
  };

  useEffect(() => {
    fetchProjects();
    fetchUserDetails();
  }, [customerId]);

  useEffect(() => {
    let activeP =
      projects.filter((data) => data.currentStatus !== "Completed") || [];
    setActiveProjects(activeP);
    setCompletedProjects(
      projects.filter((data) => data.currentStatus === "Completed") || []
    );
  }, [projects]);
  useEffect(() => {
    setStats({
      totalProjects: String(projects.length).padStart(2, "0"),
      completedOrders: String(completedProjects.length).padStart(2, "0"),
      activeOrders: String(activeProjects.length).padStart(2, "0"),
    });
    let pStatus = {};
    for (var i = 0; i < projects.length; i++) {
      pStatus[projects[i]._id] = projects[i].currentStatus;
    }
    setUpdateProjectStatus(pStatus);
  }, [activeProjects, completedProjects]);

  const handleHistory = (project) => {
    setSelectedProject(project);
    setShowOrderHistory(true);
  };

  const handleUpload = (project) => {
    setSelectedProject(project);
    setShowUploadFiles(true);
  };

  const handleOrderDetails = (project) => {
    setSelectedProject(project);
    navigate(
      `/admin/customer/${customerId}/project/${project._id}/orderdetails`
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    return day + "-" + month + "-" + year;
  };

  // if (loading) return (
  //   <div className="min-h-screen flex items-center justify-center bg-gray-50">
  //     <div className="loading-state text-lg text-gray-600">Loading...</div>
  //   </div>
  // );

  if (error)
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="error-state text-lg text-red-600">Error: {error}</div>
      </div>
    );

  const handleStatusChange = async (projectId, spaceId, newStatus) => {
    console.log("===id, newStatus", projectId, spaceId, newStatus);
    // setUpdateProjectStatus((prev) => ({
    //   ...prev,
    //   [id]: newStatus,
    // }));
    await updateSpaceStatusBySpaceId(dispatch, projectId, spaceId, {
      currentStatus: newStatus,
    });
    // await updateProjectsByProjectId(dispatch, id, { currentStatus: newStatus });
    fetchProjects();
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header with Navigation and Stats */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center">
            <h2 className="large-text">Administrator's Dashboard</h2>
            <span className="mx-2 text-gray-400">{">"}</span>
            <span className="large-text">
              {user?.firstName} {user?.lastName}'s Orders
            </span>
          </div>
          <div className="flex items-center space-x-8">
            <StatsIndicator
              label="Total Projects"
              value={stats.totalProjects}
            />
            <StatsIndicator
              label="Projects Completed"
              value={stats.completedOrders}
            />
          </div>
        </div>

        {/* Projects Table */}
        <div className="bg-white shadow-sm rounded-lg">
          {stats.totalProjects > 0 ? (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    rowSpan={2}
                    className="px-6 py-3 text-center small-text uppercase tracking-wider w-[60px]"
                  >
                    #
                  </th>
                  <th
                    rowSpan={2}
                    className="px-6 py-3 text-center small-text uppercase tracking-wider"
                  >
                    Project Name
                  </th>
                  <th
                    colSpan={4}
                    className="px-6 py-3 text-center small-text uppercase tracking-wider"
                  >
                    Room Details
                  </th>
                  <th
                    rowSpan={2}
                    className="px-6 py-3 text-center small-text uppercase tracking-wider w-[200px]"
                  >
                    Actions
                  </th>
                </tr>
                <tr>
                  <th>
                    <div className="px-6 py-3 text-center small-text uppercase tracking-wider">
                      Room
                    </div>
                  </th>
                  <th className="px-6 py-3 text-center small-text uppercase tracking-wider">
                    Room Status
                  </th>
                  <th className="px-6 py-3 text-center small-text uppercase tracking-wider">
                    Update Status
                  </th>
                  <th className="px-6 py-3 text-center small-text uppercase tracking-wider">
                    Last Updated On
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {projects?.map((project, index) => (
                  <tr key={project._id || index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
                      {String(index + 1).padStart(2, "0")}
                    </td>
                    <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">
                      {project.projectName}
                    </td>
                    <td className="text-left">
                      {project?.spaces &&
                        project.spaces.map((space) => (
                          <div
                            key={space._id}
                            className={`text-sm px-3 py-1.5 rounded`}
                          >
                            {space.name}
                          </div>
                        ))}
                    </td>
                    <td className="text-left">
                      {project?.spaces &&
                        project.spaces.map((space) => (
                          <div
                            key={space._id}
                            className={`text-sm px-3 py-1.5`}
                          >
                            {space.currentStatus}
                          </div>
                        ))}
                    </td>
                    <td className="text-left">
                      {project?.spaces &&
                        project.spaces.map((space) => (
                          <div className="py-1 w-64">
                            <UpdateStatusDropdown
                              value={space.currentStatus}
                              onChange={(newStatus) =>
                                handleStatusChange(
                                  project._id,
                                  space._id,
                                  newStatus
                                )
                              }
                            />
                          </div>
                        ))}
                    </td>
                    {/* <td className="px-4 py-3 w-64">
                      <UpdateStatusDropdown
                        value={updateProjectStatus[project._id]}
                        onChange={(newStatus) =>
                          handleStatusChange(project._id, newStatus)
                        }
                      />
                    </td> */}
                    <td className="text-center">
                      {project?.spaces &&
                        project.spaces.map((space) => (
                          <div
                            key={space._id}
                            className={`text-sm px-3 py-1.5 rounded`}
                          >
                            {formatDate(space.updatedAt)}
                          </div>
                        ))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-left">
                      <div className="flex flex-col space-y-2">
                        <div className="flex space-x-2">
                          {/* <ActionButton
                            icon={History}
                            label="History"
                            variant="light"
                            onClick={() => handleHistory(project)}
                          /> */}
                          <ActionButton
                            icon={FileText}
                            label="Order Details"
                            variant="dark"
                            onClick={() => handleOrderDetails(project)}
                          />
                        </div>
                        {/* <ActionButton
                          icon={Download}
                          label="Uploaded Files"
                          variant="light"
                          onClick={() => handleUpload(project)}
                        /> */}

                        <div className="flex flex-col space-y-2">
                          <ActionButton
                            label="Customer Uploads"
                            variant="light"
                            onClick={() =>
                              navigate(
                                `/admin/project/${project._id}/customer-uploads`
                              )
                            }

                            // navigate('/admin/uploads/customer')}
                          />
                          <ActionButton
                            label="Furdexo Uploads"
                            variant="light"
                            onClick={() =>
                              navigate(
                                `/admin/project/${project._id}/furdexo-uploads`
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div style={{ padding: "15px" }} className="flex items-center">
              No project found
            </div>
          )}
        </div>

        {/* Modals */}
        {showOrderHistory && selectedProject && (
          <OrderHistory
            project={selectedProject}
            onClose={() => {
              setShowOrderHistory(false);
              setSelectedProject(null);
            }}
          />
        )}

        {showUploadFiles && selectedProject && (
          <UploadFiles
            project={selectedProject}
            onClose={() => {
              setShowUploadFiles(false);
              setSelectedProject(null);
            }}
            onFileUpload={user}
          />
        )}
      </main>
    </div>
  );
};

export default CustomerInfo;
