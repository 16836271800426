// furdexo-app-frontend/src/shared/layout/Layout.js
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ChevronLeft, Home } from "lucide-react";
import ProfileSettings from "../../pages/Customers/ProfileSettings";
import furdexo_logo from "../../static/images/furdexo-logo.png";
import ResetPassword from "../../components/Auth/ResetPassword";
import { useDispatch } from "react-redux";
import { API_BASE_URL } from "../../config/url";
import ManageItemCategory from "../../pages/Admin/ManageItemCategory";

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileSetting, setProfileSetting] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isManageItemCategoryModal, setIsManageItemCategoryModal] =
    useState(false);
  var profileDetails = localStorage.getItem("user") || "{}";
  profileDetails = JSON.parse(profileDetails);

  const handleLogout = () => {
    localStorage.clear();
    dispatch({ type: "USER_LOGOUT_SUCCESS", apiResponse: null });
    navigate("/login");
  };

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
            <div className="flex items-center gap-4">
              <div>
                <img
                  src={furdexo_logo}
                  alt="Furdexo Logo"
                  width={"163px"}
                  height={"37px"}
                />
              </div>
            </div>
            <div className="flex items-center gap-4">
              {/* {profileDetails && profileDetails.role === 1 && (
                <div>
                  <button
                    onClick={() => navigate("/admin/dashboard")}
                    className="flex items-center gap-2 px-3 py-1 bg-gray-700 text-white rounded-md hover:bg-gray-800"
                  >
                    Administrator
                  </button>
                </div>
              )} */}
              <div>
                Welcome back, {profileDetails.firstName}{" "}
                {profileDetails.lastName}!
              </div>
              <div className="relative">
                {profileDetails.companyLogoFile ? (
                  <img
                    onClick={() => setShowProfileMenu(!showProfileMenu)}
                    width={25}
                    style={{ height: "25px" }}
                    src={`${API_BASE_URL}/${profileDetails.companyLogoFile}`}
                    alt="Profile"
                    className="cursor-pointer rounded-full object-cover companyLogoFile"
                  />
                ) : (
                  <button
                    onClick={() => setShowProfileMenu(!showProfileMenu)}
                    className="w-8 h-8 bg-gray-200 rounded-full"
                  />
                )}

                {showProfileMenu && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                    <button
                      onClick={() => {
                        setShowProfileMenu(!showProfileMenu);
                        setIsManageItemCategoryModal(true);
                      }}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    >
                      Manage Category
                    </button>
                    <button
                      onClick={() => {
                        setShowProfileMenu(!showProfileMenu);
                        setProfileSetting(true);
                      }}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    >
                      View Profile
                    </button>
                    <button
                      onClick={() => {
                        setShowProfileMenu(!showProfileMenu);
                        setResetPassword(true);
                      }}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                    >
                      Reset Password
                    </button>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
        <Outlet />
        {/* <footer className="bg-white shadow mt-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <p className="text-center text-gray-500 text-sm">
              © {new Date().getFullYear()} Furdexo. All rights reserved.
            </p>
          </div>
        </footer> */}
        <ProfileSettings
          id={profileDetails._id}
          isOpen={profileSetting}
          onClose={() => setProfileSetting(false)}
        />
        <ResetPassword
          isOpen={resetPassword}
          onClose={() => setResetPassword(false)}
        />
        {isManageItemCategoryModal && (
          <ManageItemCategory
            isOpen={isManageItemCategoryModal}
            onClose={() => setIsManageItemCategoryModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default Layout;
