import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, X, Search, Plus, Edit, Trash2 } from "lucide-react";
import { getProjects } from "../../actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../actions/userActions";
import EditIcon from "../../components/Icons/EditIcon";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import AddIcon from "../../components/Icons/AddIcon";
import {
  addCategory,
  addSubCategory,
  addSubCategoryItem,
  deleteSubCategoryItem,
  getCategories,
  updateSubCategoryItem,
} from "../../actions/categoryActions";
import Processing from "../../components/Processing/Processing";

const ManageItemCategory = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false);
  const [filterCategories, setFilterCategories] = useState([]);
  const [filterSubCategories, setFilterSubCategories] = useState([]);
  const [filterSubCategoryItems, setFilterSubCategoryItems] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [selectedSubCategoryAction, setSelectedSubCategoryAction] =
    useState("");

  const [selectedSubCategoryItem, setSelectedSubCategoryItem] = useState("");

  const [formSubCategoryItem, setFormSubCategoryItem] = useState({
    name: "",
    description: "",
    unitOfMeasurement: "",
    ratePerUnit: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormSubCategoryItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { categories, apiLoading, apiSuccess } = useSelector(
    (state) => state.category
  );

  const [newCategory, setNewCategory] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");

  const handleAddCategory = () => {
    if (newCategory.trim()) {
      addCategory(dispatch, { name: newCategory.trim() });
      setNewCategory("");
      setShowAddCategoryModal(false);
    }
    setFormSubCategoryItem({
      name: "",
      description: "",
      unitOfMeasurement: "",
      ratePerUnit: "",
      hsnCode: "",
      gst: "",
      discount: "",
    });
  };

  const handleAddSubCategory = () => {
    if (newSubCategory.trim()) {
      addSubCategory(dispatch, selectedCategory, {
        name: newSubCategory.trim(),
      });
      setNewSubCategory("");
      setShowAddSubCategoryModal(false);
      // handleGetCategories();
    }
    setFormSubCategoryItem({
      name: "",
      description: "",
      unitOfMeasurement: "",
      ratePerUnit: "",
      hsnCode: "",
      gst: "",
      discount: "",
    });
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    addSubCategoryItem(dispatch, selectedCategory, selectedSubCategory, {
      ...formSubCategoryItem,
    });
    setFormSubCategoryItem({
      name: "",
      description: "",
      unitOfMeasurement: "",
      ratePerUnit: "",
      hsnCode: "",
      gst: "",
      discount: "",
    });
  };

  const handleEditItem = () => {
    setSelectedSubCategoryAction("EditItem");
  };

  const handleGetCategories = async () => {
    await getCategories(dispatch, {});
  };

  const handleSubCategories = async (id) => {
    const category = categories.find((category) => category._id === id);
    const subcategories = category ? category.subcategories : [];
    // console.log(subcategories);
    setFilterSubCategories(subcategories);
  };

  const handleSubCategoriesItems = async (id) => {
    const category = categories.find(
      (category) => category._id === selectedCategory
    );
    const subcategories = category ? category.subcategories : [];
    const flterSubcategories = subcategories.find(
      (subcategory) => subcategory._id === id
    );
    const subCategoriesItems = flterSubcategories
      ? flterSubcategories.items
      : [];
    // console.log(subCategoriesItems);
    setFilterSubCategoryItems(subCategoriesItems);
  };

  const handleSubCategoriesItemsSearch = async (value) => {
    setSearchQuery(value);
    const subcategories = filterSubCategories.find(
      (subcategory) => subcategory._id === selectedSubCategory
    );
    const subCategoriesItems = subcategories ? subcategories.items : [];
    const filtered = subCategoriesItems.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log(filtered);
    setFilterSubCategoryItems(filtered);
  };

  const handleEditSubCategoryItem = async () => {
    updateSubCategoryItem(
      dispatch,
      selectedCategory,
      selectedSubCategory,
      formSubCategoryItem._id,
      {
        ...formSubCategoryItem,
      }
    );
  };

  const handleDeleteSubCategoryItem = async () => {
    deleteSubCategoryItem(
      dispatch,
      selectedCategory,
      selectedSubCategory,
      selectedSubCategoryItem._id
    );
  };
  useEffect(() => {
    handleGetCategories();
  }, []);

  const refreshCategory = () => {
    if (selectedCategory) handleSubCategories(selectedCategory);
    if (selectedSubCategory) handleSubCategoriesItems(selectedSubCategory);
  };

  useEffect(() => {
    if (!apiLoading) {
      setFilterCategories(categories);
      refreshCategory();
    }
  }, [apiLoading, categories]);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-6xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          {/* Header */}
          <div className="flex justify-between items-start mb-6">
            <div className="flex items-center gap-3">
              <div className="flex justify-between items-start">
                <div className="text-lg font-medium">Manage Item Category</div>
              </div>
            </div>
            <div className="flex justify-between items-start mb-6">
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <X size={20} className="text-gray-500" />
              </button>
            </div>
          </div>
          {apiLoading ? (
            <Processing />
          ) : (
            <>
              <div className="flex justify-between items-center mb-6">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                  {filterCategories.map((category) => (
                    <button
                      key={category._id}
                      onClick={() => {
                        handleSubCategories(category._id);
                        setSelectedCategory(category._id);
                        setSelectedSubCategory("");
                        setFormSubCategoryItem({
                          name: "",
                          description: "",
                          unitOfMeasurement: "",
                          ratePerUnit: "",
                          hsnCode: "",
                          gst: "",
                          discount: "",
                        });
                      }}
                      className={`px-4 py-2 rounded ${
                        selectedCategory === category._id
                          ? "bg-gray-600 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {category.name}
                    </button>
                  ))}
                </div>
                <button
                  onClick={() => setShowAddCategoryModal(true)}
                  className="bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-gray-800"
                >
                  Add New Category
                </button>
              </div>
              {selectedCategory !== "" && (
                <div className="flex justify-between items-center mb-6">
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                    {filterSubCategories.map((subcategory) => (
                      <button
                        key={subcategory._id}
                        onClick={() => {
                          handleSubCategoriesItems(subcategory._id);
                          setSelectedSubCategory(subcategory._id);
                          setFormSubCategoryItem({
                            name: "",
                            description: "",
                            unitOfMeasurement: "",
                            ratePerUnit: "",
                          });
                          setSelectedSubCategoryAction("AddItem");
                        }}
                        className={`px-4 py-2 rounded ${
                          selectedSubCategory === subcategory._id
                            ? "bg-gray-600 text-white"
                            : "bg-gray-200"
                        }`}
                      >
                        {subcategory.name}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setShowAddSubCategoryModal(true)}
                    className="bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-gray-800"
                  >
                    Add New Subcategory
                  </button>
                </div>
              )}
              {selectedSubCategory !== "" && (
                <div className="grid grid-cols-3 gap-6">
                  {/* Left Column - Actions */}
                  <div className="space-y-3">
                    <button
                      onClick={() => {
                        setFormSubCategoryItem({
                          name: "",
                          description: "",
                          unitOfMeasurement: "",
                          ratePerUnit: "",
                          hsnCode: "",
                          gst: "",
                          discount: "",
                        });
                        setSelectedSubCategoryAction("AddItem");
                      }}
                      className={`w-full py-2 rounded flex flex-col items-center gap-1 ${
                        selectedSubCategoryAction === "AddItem"
                          ? "bg-gray-700 text-white"
                          : "bg-gray-100"
                      }`}
                    >
                      <AddIcon
                        color={
                          selectedSubCategoryAction === "AddItem"
                            ? "white"
                            : "#1E1E1E"
                        }
                      />
                      <span className="text-sm">Add New Item</span>
                    </button>
                    <button
                      onClick={handleEditItem}
                      className={`w-full py-2 rounded flex flex-col items-center gap-1 ${
                        selectedSubCategoryAction === "EditItem"
                          ? "bg-gray-700 text-white"
                          : "bg-gray-100"
                      }`}
                    >
                      <EditIcon
                        color={
                          selectedSubCategoryAction === "EditItem"
                            ? "white"
                            : "#1E1E1E"
                        }
                      />
                      <span className="text-sm">Update Item</span>
                    </button>
                    <button
                      onClick={() => {
                        setSelectedSubCategoryAction("DeleteItem");
                        setFormSubCategoryItem({
                          name: "",
                          description: "",
                          unitOfMeasurement: "",
                          ratePerUnit: "",
                          hsnCode: "",
                          gst: "",
                          discount: "",
                        });
                      }}
                      className={`w-full py-2 rounded flex flex-col items-center gap-1 ${
                        selectedSubCategoryAction === "DeleteItem"
                          ? "bg-gray-700 text-white"
                          : "bg-gray-100"
                      }`}
                    >
                      <DeleteIcon
                        color={
                          selectedSubCategoryAction === "DeleteItem"
                            ? "white"
                            : "#1E1E1E"
                        }
                      />
                      <span className="text-sm">Delete Item</span>
                    </button>
                  </div>

                  {/* Middle Column - Search and List */}
                  <div>
                    <div className="relative mb-3">
                      <input
                        type="text"
                        placeholder="Search tags"
                        value={searchQuery}
                        onChange={(e) =>
                          handleSubCategoriesItemsSearch(e.target.value)
                        }
                        className="w-full pl-10 pr-4 py-2 border rounded"
                      />
                      <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    </div>

                    <div
                      className="border rounded-lg overflow-hidden"
                      style={{ maxHeight: "450px", overflowY: "scroll" }}
                    >
                      {filterSubCategoryItems &&
                      filterSubCategoryItems.length > 0 ? (
                        filterSubCategoryItems.map((subCategoryItem) => (
                          <div
                            key={subCategoryItem._id}
                            onClick={() => {
                              setSelectedSubCategoryItem(subCategoryItem);
                              setFormSubCategoryItem({
                                ...subCategoryItem,
                              });
                            }}
                            className={`px-4 py-2 cursor-pointer border-b last:border-b-0 ${
                              selectedSubCategoryItem._id ===
                              subCategoryItem._id
                                ? "bg-gray-700 text-white"
                                : ""
                            }`}
                          >
                            {subCategoryItem.name}
                          </div>
                        ))
                      ) : (
                        <div className="px-4 py-2">No item found</div>
                      )}
                    </div>
                  </div>

                  {/* Right Column - Add New Item Form */}
                  {selectedSubCategoryAction === "AddItem" && (
                    <div className="border rounded-lg p-4">
                      <h3 className="font-medium mb-4">Add New Item</h3>
                      <form className="space-y-4" onSubmit={handleAddItem}>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            HSN Code
                          </label>
                          <input
                            type="text"
                            name="hsnCode"
                            value={formSubCategoryItem.hsnCode}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            Item Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={formSubCategoryItem.name}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            Description
                          </label>
                          <textarea
                            name="description"
                            value={formSubCategoryItem.description}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                            rows={3}
                          />
                        </div>
                        <div className="relative">
                          <label className="block text-sm font-medium text-gray-600 mb-1">
                            Unit of Measurement
                          </label>
                          <select
                            name="unitOfMeasurement"
                            value={formSubCategoryItem.unitOfMeasurement}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 bg-white border border-gray-300 rounded focus:outline-none focus:border-gray-400 appearance-none cursor-pointer"
                          >
                            <option value="">Select unit</option>
                            <option value="RFT">RFT</option>
                            <option value="RMT">RMT</option>
                            <option value="SQFT">SQFT</option>
                            <option value="SQMT">SQMT</option>
                            <option value="Nos.">Nos.</option>
                            <option value="LS">LS</option>
                          </select>
                          <div className="absolute right-3 top-[34px] pointer-events-none">
                            <svg
                              className="h-5 w-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            Rate / Unit
                          </label>
                          <input
                            type="text"
                            name="ratePerUnit"
                            value={formSubCategoryItem.ratePerUnit}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            GST
                          </label>
                          <input
                            type="text"
                            name="gst"
                            value={formSubCategoryItem.gst}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            Discount
                          </label>
                          <input
                            type="text"
                            name="discount"
                            value={formSubCategoryItem.discount}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div className="flex space-x-2">
                          <button
                            type="submit"
                            className="px-6 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                          >
                            Add Item
                          </button>
                          <button
                            type="button"
                            className="px-6 py-2 bg-gray-200 rounded hover:bg-gray-300"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                  {selectedSubCategoryAction === "EditItem" && (
                    <div className="border rounded-lg p-4">
                      <h3 className="font-medium mb-4">
                        Update {formSubCategoryItem.name}
                      </h3>
                      <form className="space-y-4" onSubmit={handleAddItem}>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            HSN Code
                          </label>
                          <input
                            type="text"
                            name="hsnCode"
                            value={formSubCategoryItem.hsnCode}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            Item Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={formSubCategoryItem.name}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            Description
                          </label>
                          <textarea
                            name="description"
                            value={formSubCategoryItem.description}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                            rows={3}
                          />
                        </div>
                        <div className="relative">
                          <label className="block text-sm font-medium text-gray-600 mb-1">
                            Unit of Measurement
                          </label>
                          <select
                            name="unitOfMeasurement"
                            value={formSubCategoryItem.unitOfMeasurement}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 bg-white border border-gray-300 rounded focus:outline-none focus:border-gray-400 appearance-none cursor-pointer"
                          >
                            <option value="">Select unit</option>
                            <option value="RFT">RFT</option>
                            <option value="RMT">RMT</option>
                            <option value="SQFT">SQFT</option>
                            <option value="SQMT">SQMT</option>
                            <option value="Nos.">Nos.</option>
                            <option value="LS">LS</option>
                          </select>
                          <div className="absolute right-3 top-[34px] pointer-events-none">
                            <svg
                              className="h-5 w-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            Rate / Unit
                          </label>
                          <input
                            type="text"
                            name="ratePerUnit"
                            value={formSubCategoryItem.ratePerUnit}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            GST
                          </label>
                          <input
                            type="text"
                            name="gst"
                            value={formSubCategoryItem.gst}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-sm text-gray-600 mb-1">
                            Discount
                          </label>
                          <input
                            type="text"
                            name="discount"
                            value={formSubCategoryItem.discount}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded"
                          />
                        </div>
                        <div className="flex space-x-2">
                          <button
                            type="submit"
                            onClick={handleEditSubCategoryItem}
                            className="px-6 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                          >
                            Update Item
                          </button>
                          <button
                            type="button"
                            className="px-6 py-2 bg-gray-200 rounded hover:bg-gray-300"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                  {selectedSubCategoryAction === "DeleteItem" && (
                    <div className="border rounded bg-white">
                      <div className="p-6">
                        <h3 className="font-medium mb-4">Delete Item</h3>
                        <div className="text-sm text-gray-700">
                          Are you sure you want delete "
                          {selectedSubCategoryItem?.name}"?
                        </div>
                        <div className="mt-6 flex gap-3">
                          <button
                            onClick={handleDeleteSubCategoryItem}
                            className="px-4 py-2 bg-gray-600 text-white text-sm font-medium rounded hover:bg-gray-700 disabled:opacity-50"
                          >
                            Confirm Delete
                          </button>
                          <button className="px-4 py-2 bg-gray-200 text-gray-700 text-sm font-medium rounded hover:bg-gray-300">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {showAddCategoryModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4">Add New Category</h3>
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Category Name"
              className="w-full p-2 border border-gray-300 rounded-md mb-4"
            />
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowAddCategoryModal(false)}
                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleAddCategory}
                className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800"
              >
                Add Category
              </button>
            </div>
          </div>
        </div>
      )}
      {showAddSubCategoryModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold mb-4">Add New Subcategory</h3>
            <input
              type="text"
              value={newSubCategory}
              onChange={(e) => setNewSubCategory(e.target.value)}
              placeholder="Sub Category Name"
              className="w-full p-2 border border-gray-300 rounded-md mb-4"
            />
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowAddSubCategoryModal(false)}
                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleAddSubCategory}
                className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800"
              >
                Add Sub Category
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageItemCategory;
