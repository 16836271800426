import React, { useEffect, useState } from "react";
import { forgotPasswordUser } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({});
  const { apiLoading, apiSuccess, apiResponseStatus } = useSelector(
      (state) => state.user
    );

  const handleChange = (e) => {
    setEmail(e.target.value);
    setErrors({});
  };

  const validate = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {  
        await forgotPasswordUser(dispatch, { email });        
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
console.log(
  "===apiLoading, apiSuccess",
  apiLoading,
  apiSuccess,
  apiResponseStatus
);
useEffect(() => {
  if (!apiLoading && apiSuccess) {
    if (apiResponseStatus) {
      navigate("/login");
    }
  }
}, [apiLoading, apiSuccess, apiResponseStatus]);
  return (
    <div className="max-w-md mx-auto p-6">
      <div className="bg-white rounded-lg shadow-sm border p-8">
        <h2 className="text-xl font-semibold mb-6">Forgot Password</h2>

        <div className="m-4 flex">
          <a
            href="/login"
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="mr-2" size={18} />
            Back to Login
          </a>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              className={`w-full p-3 rounded-md bg-gray-50`}
              onChange={handleChange}
              value={email}
            />
            {errors.email && (
              <p className="mt-1 text-red-500 text-sm">{errors.email}</p>
            )}
          </div>

          <div className="pt-2 flex justify-center">
            <button
              type="submit"
              className="px-8 py-3 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
            >
              Forgot Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
