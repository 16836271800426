// furdexo-app-frontend/src/shared/layout/Layout.js
import React from "react";
import { Outlet } from "react-router-dom";
import furdexo_logo from "../../static/images/furdexo-logo.png";

const NonLoginLayout = () => {
  return (
    <>
      <div className="min-h-screen bg-gray-50">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
            <div className="flex items-center gap-4">
              <div>
                <img
                  src={furdexo_logo}
                  alt="Furdexo Logo"
                  width={"163px"}
                  height={"37px"}
                />
              </div>
            </div>
          </div>
        </header>
        <Outlet />
      </div>
    </>
  );
};

export default NonLoginLayout;
