import axios from "axios";
import { API_BASE_URL } from "../config/url";
import { toast } from "react-toastify";

axios.interceptors.request.use(
  (config) => {
    config.baseURL = API_BASE_URL;
    const token = window.localStorage.getItem("token") || "";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.data &&
      [9010, 9011].includes(error.response.data.response_code)
    ) {
      toast.error(error.response.data.message, {
        position: "bottom-center",
      });
      localStorage.clear();
      window.location.href = "/login";
      return Promise.reject(error.response.data);
    } else {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  }
);
