// furdexo-app-frontend/src/action/userActions.js
import axios from "axios";
import {
  urlAuthForgotPassword,
  urlAuthLogin,
  urlAuthResetPassword,
  urlUserRegistration,
  urlUser,
} from "../config/url";

export async function userRegistration(dispatch, payload) {
  try {
    const response = await axios.post(urlUserRegistration, payload);
    dispatch({ type: "USER_REGISTER_SUCCESS", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "USER_ERROR", apiResponse: error.response.data });
  }
}

export async function getUserDetails(dispatch, userId) {
  try {
    const response = await axios.get(`${urlUserRegistration}/${userId}`);
    dispatch({ type: "USER_FETCH_SUCCESS", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "USER_ERROR", apiResponse: error.response.data });
  }
}

export async function loginUser(dispatch, payload) {
  try {
    const response = await axios.post(urlAuthLogin, payload);
    dispatch({ type: "USER_LOGIN_SUCCESS", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "USER_ERROR", apiResponse: error });
  }
}

export async function getUsers(dispatch) {
  try {
    const response = await axios.get(urlUserRegistration);
    dispatch({ type: "USERS_FETCH_SUCCESS", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "USER_ERROR", apiResponse: error.response.data });
  }
}

export async function resetPasswordUser(dispatch, payload) {
  try {
    const response = await axios.post(urlAuthResetPassword, payload);
    dispatch({
      type: "USER_RESET_PASSWORD_SUCCESS",
      apiResponse: response.data,
    });
  } catch (error) {
    dispatch({ type: "USER_ERROR", apiResponse: error });
  }
}

export async function forgotPasswordUser(dispatch, payload) {
  try {
    const response = await axios.post(urlAuthForgotPassword, payload);
    dispatch({
      type: "USER_FORGOT_PASSWORD_SUCCESS",
      apiResponse: response.data,
    });
  } catch (error) {
    dispatch({ type: "USER_ERROR", apiResponse: error });
  }
}

export async function updateUser(dispatch, id, payload) {
  try {
    const response = await axios.put(`${urlUser}/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({
      type: "USER_UPDATE_SUCCESS",
      apiResponse: response.data,
    });
  } catch (error) {
    dispatch({ type: "USER_ERROR", apiResponse: error });
  }
}
