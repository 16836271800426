import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Eye, EyeOff, Mail, Lock, AlertCircle } from "lucide-react";
import { loginUser } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import furdexo_logo from "../../static/images/furdexo-logo.png";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { user, apiLoading, apiSuccess } = useSelector((state) => state.user);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(""); // Clear error when user types
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    await loginUser(dispatch, { ...formData });
  };
  useEffect(() => {
    if (!apiLoading && apiSuccess && user) {
      if (user?.response_status) {
        toast.success(user.message, {
          position: "bottom-center",
        });
        localStorage.setItem("token", user.results.token);
        localStorage.setItem("user", JSON.stringify(user.results.user));
        if (user.results.user.role === 1) {
          navigate(`/admin/dashboard`);
        } else {
          navigate(`/customer/enquiries`);
        }
      } else {
        toast.error(user?.message || "Error whle sign in", {
          position: "bottom-center",
        });
      }
      setLoading(false);
    }
  }, [apiLoading, apiSuccess, user]);
  console.log(
    "========user, apiLoading, apiSuccess",
    user,
    apiLoading,
    apiSuccess
  );
  return (
    <div className="max-w-md mx-auto p-6">
      <div className="text-center mb-8">
        <h1 className="text-2xl font-semibold mb-2">Welcome to</h1>
        <img src={furdexo_logo} alt="Furdexo Logo" className="mx-auto" />
      </div>

      <div className="bg-white rounded-lg shadow-sm border p-4">
        <h2 className="medium-text mb-6">
          Login to design and deliver your ideas
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="w-full p-3 rounded-md bg-gray-50"
            onChange={handleInputChange}
            value={formData.email}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="w-full p-3 rounded-md bg-gray-50"
            onChange={handleInputChange}
            value={formData.password}
          />

          <div className="pt-2">
            <button
              type="submit"
              className="w-full py-3 button-decorator rounded-md"
            >
              Sign In
            </button>
          </div>

          <div className="text-center mt-4">
            <a href="/forgot-password" className="base-text hover:underline">
              Forgot Password? Set a new one.
            </a>
          </div>
        </form>
      </div>

      <div className="text-center mt-6">
        <a href="/signup" className="base-text hover:underline">
          Create a new account
        </a>
      </div>
    </div>
  );
};

export default Login;
