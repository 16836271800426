// furdexo-app-frontend/src/shared/layout/Layout.js
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";

const BackButtonLayout = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="min-h-screen bg-white">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex items-center gap-4">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center gap-2 px-3 py-1 button-decorator rounded-md"
            >
              <ChevronLeft size={15} />
              Back
            </button>
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default BackButtonLayout;
