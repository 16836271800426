// furdexo-app-frontend/src/reducers/userReducers.js

import { toast } from "react-toastify";
const initialState = {
  message: null,
  apiResponse: null,
  apiLoading: true,
  apiSuccess: false,
  apiResponseStatus: false,
  categories: [],
  category: {},
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQ_CATEGORY":
      return {
        ...state,
        apiLoading: true,
        apiSuccess: false,
        apiResponseStatus: false,
      };
    case "FETCH_CATEGORIES":
      return {
        ...state,
        message: action.apiResponse.message,
        categories: action.apiResponse.results,
        apiResponseStatus: action.apiResponse.response_status,
        apiLoading: false,
        apiSuccess: true,
      };
    case "ADD_CATEGORY_SUBCATEGORY_ITEM":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        apiResponse: action.payload,
        category: action.apiResponse.results,
        apiLoading: false,
        apiSuccess: true,
      };
    case "ADD_CATEGORY":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        apiResponse: action.payload,
        categories: action.apiResponse.results,
        apiLoading: false,
        apiSuccess: true,
      };
    case "ADD_SUBCATEGORY":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        apiResponse: action.payload,
        categories: action.apiResponse.results,
        apiLoading: false,
        apiSuccess: true,
      };
    case "ADD_SUBCATEGORY_ITEM":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        categories: action.apiResponse.results,
        apiResponse: action.payload,
        apiLoading: false,
        apiSuccess: true,
      };
    case "UPDATE_SUBCATEGORY_ITEM":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        categories: action.apiResponse.results,
        apiResponse: action.payload,
        apiLoading: false,
        apiSuccess: true,
      };
    case "DELETE_SUBCATEGORY_ITEM":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        categories: action.apiResponse.results,
        apiResponse: action.payload,
        apiLoading: false,
        apiSuccess: true,
      };
    case "CATEGORY_ERROR":
      toast.error(action.apiResponse.message, {
        position: "bottom-center",
      });
      return { ...state, apiResponse: action.apiResponse, apiSuccess: true };
    default:
      return state;
  }
};
