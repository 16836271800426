import React, { useState } from "react";
import { resetPasswordUser } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { X } from "lucide-react";

const ResetPassword = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();  
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setPasswords((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrors({});
  };

  const validate = () => {
    const newErrors = {};
    if (!passwords.newPassword)
      newErrors.newPassword = "New password is required";
    if (!passwords.confirmPassword)
      newErrors.confirmPassword = "Confirm password is required";
    if (passwords.newPassword !== passwords.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        await resetPasswordUser(dispatch, { password: passwords.newPassword });
        onClose(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-100 rounded-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-medium">Forgot Password</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>

        <div className="max-w-md mx-auto p-6">
          <div className="bg-white rounded-lg shadow-sm border p-8">
            {/* <h2 className="text-xl font-semibold mb-6">Forgot Password</h2> */}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="password"
                  name="newPassword"
                  placeholder="Add New Password"
                  className={`w-full p-3 rounded-md bg-gray-50 ${
                    errors.newPassword ? "border-red-500" : ""
                  }`}
                  onChange={handleChange}
                  value={passwords.newPassword}
                />
                {errors.newPassword && (
                  <p className="mt-1 text-red-500 text-sm">
                    {errors.newPassword}
                  </p>
                )}
              </div>

              <div>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className={`w-full p-3 rounded-md bg-gray-50 ${
                    errors.confirmPassword ? "border-red-500" : ""
                  }`}
                  onChange={handleChange}
                  value={passwords.confirmPassword}
                />
                {errors.confirmPassword && (
                  <p className="mt-1 text-red-500 text-sm">
                    {errors.confirmPassword}
                  </p>
                )}
              </div>

              <div className="pt-2 flex justify-center">
                <button
                  type="submit"
                  className="px-8 py-3 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
                >
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
