// furdexo-app-frontend/src/action/userActions.js
import axios from "axios";
import {
  urlInvoice,
  urlInvoices,
} from "../config/url";

export async function getCustomerInvoice(dispatch, payload) {
  try {
    dispatch({ type: "REQ_INVOICE", apiResponse: {} });
    const response = await axios.post(urlInvoice, payload);
    dispatch({ type: "FETCH_INVOICE", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "ERROR_INVOICE", apiResponse: error });
  }
}

export async function addCustomerInvoice(dispatch, payload) {
  try {
    dispatch({ type: "REQ_INVOICE", apiResponse: {} });
    const response = await axios.post(urlInvoices, payload);
    dispatch({ type: "ADD_INVOICE", apiResponse: response.data });
  } catch (error) {
    dispatch({ type: "ERROR_INVOICE", apiResponse: error });
  }
}