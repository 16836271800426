import React from "react";

const AddIcon = ({ color = "#1E1E1E" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2522_21382)">
        <path
          d="M16.4423 10.0123L10.0137 10.0123L10.0137 16.4408C10.0137 16.7818 9.87828 17.1089 9.63717 17.35C9.39605 17.5911 9.06902 17.7266 8.72803 17.7266C8.38703 17.7266 8.06001 17.5911 7.81889 17.35C7.57777 17.1089 7.44231 16.7818 7.44231 16.4408V10.0123H1.01374C0.672749 10.0123 0.345723 9.87682 0.104605 9.6357C-0.136514 9.39458 -0.271973 9.06755 -0.271973 8.72656C-0.271973 8.38557 -0.136514 8.05854 0.104605 7.81742C0.345723 7.57631 0.672749 7.44085 1.01374 7.44085H7.44231V1.01228C7.44231 0.671284 7.57777 0.344257 7.81889 0.103139C8.06001 -0.137979 8.38703 -0.273438 8.72803 -0.273438C9.06902 -0.273438 9.39605 -0.137979 9.63717 0.103139C9.87828 0.344257 10.0137 0.671284 10.0137 1.01228V7.44085H16.4423C16.7833 7.44085 17.1103 7.57631 17.3515 7.81742C17.5926 8.05854 17.728 8.38557 17.728 8.72656C17.728 9.06755 17.5926 9.39458 17.3515 9.6357C17.1103 9.87682 16.7833 10.0123 16.4423 10.0123Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2522_21382">
          <rect width="18" height="18" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddIcon;
