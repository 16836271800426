// furdexo-app-frontend/src/pages/customer/CustomerEnquiry.js
import React, { useState, useEffect } from "react";
import { Eye, Clock, X, Plus, Pencil } from "lucide-react";
import "./customer.scss";
import { getProjects, projectRegistration, updateProjectsByProjectId } from "../../actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ChatBot from "../../components/ChatBot/ChatBot";

const FolderIcon = () => (
  <svg
    className="w-16 h-16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
  >
    <path d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
  </svg>
);

const CreateProjectModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    projectName: "",
    city: "",
    address1: "",
    address2: "",
    description: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setFormData({
      projectName: "",
      city: "",
      address1: "",
      address2: "",
      description: "",
    });
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-medium">ENQUIRY STEP: Create Project</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(formData);
          }}
          className="space-y-4"
        >
          <input
            type="text"
            name="projectName"
            placeholder="Name"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
            value={formData.projectName}
            onChange={handleChange}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
            value={formData.city}
            onChange={handleChange}
          />
          <input
            type="text"
            name="address1"
            placeholder="Address Line 1"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
            value={formData.address1}
            onChange={handleChange}
          />
          <input
            type="text"
            name="address2"
            placeholder="Address Line 2"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
            value={formData.address2}
            onChange={handleChange}
          />
          <textarea
            name="description"
            placeholder="Description"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md h-32"
            value={formData.description}
            onChange={handleChange}
          />
          <button
            type="submit"
            className="w-full bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-800"
          >
            Create Project
          </button>
        </form>
      </div>
    </div>
  );
};

const EditProjectModal = ({ selectedProject, isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
      let projectDetails = {
        projectName: selectedProject["projectName"],
        city: selectedProject["city"],
        address1: selectedProject["address1"],
        address2: selectedProject["address2"],
      };
      setFormData(projectDetails);
  }, [selectedProject]);
  if (!isOpen) return null;
  console.log("=====formData", formData, selectedProject);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-medium">Edit Project</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(formData);
          }}
          className="space-y-4"
        >
          <input
            type="text"
            name="projectName"
            placeholder="Name"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
            value={formData.projectName}
            onChange={handleChange}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
            value={formData.city}
            onChange={handleChange}
          />
          <input
            type="text"
            name="address1"
            placeholder="Address Line 1"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
            value={formData.address1}
            onChange={handleChange}
          />
          <input
            type="text"
            name="address2"
            placeholder="Address Line 2"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
            value={formData.address2}
            onChange={handleChange}
          />
          <textarea
            name="description"
            placeholder="Description"
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md h-32"
            value={formData.description}
            onChange={handleChange}
          />
          <button
            type="submit"
            className="w-full bg-gray-700 text-white py-2 px-4 rounded-md hover:bg-gray-800"
          >
            Update Project
          </button>
        </form>
      </div>
    </div>
  );
};

const ProjectDetails = (props) => (
  <div>
    {props.project.projectName && <div className="flex justify-between">
      <h3 className="text-lg font-medium mt-2 mb-6">
        {props.project.projectName}
      </h3>
      <Pencil
        onClick={() => props.setIsEditModalOpen(true)}
        size={14}
        className="text-gray-600 cursor-pointer hover:text-gray-800 mt-4"
      />
    </div>}

    {props.project.description && (
      <>
        <div className="mt-3 font-medium">Description:</div>
        <div>{props.project.description}</div>
      </>
    )}
    {(props.project.address1 || props.project.address2) && (
      <>
        <div className="mt-3 font-medium">Address:</div>
        <div>
          {props.project.address1} {props.project.address2}
        </div>
      </>
    )}
    {props.project.city && (
      <>
        <div className="mt-3 font-medium">City:</div>
        <div>{props.project.city}</div>
      </>
    )}
    {props.project.currentStatus && (
      <>
        <div className="mt-3 font-medium">Current Status:</div>
        <div>{props.project.currentStatus}</div>
      </>
    )}
  </div>
);

const ProjectCard = ({
  _id,
  projectName,
  currentStatus,
  rooms,
  navigate,
  setSelectedProject,
  selectedProject,
  projectData,
}) => (
  <div
    className={`relative rounded-lg p-4 cursor-pointer transition-all
      ${selectedProject._id === _id ? "bg-gray-700 text-white" : "bg-white"}
    `}
    onClick={() => setSelectedProject(projectData)}
    onDoubleClick={() => navigate(`/customer/project/${_id}`)}
  >
    <div className="absolute top-4 right-4 px-2 py-1 text-xs bg-gray-100 text-gray-700 rounded">
      {currentStatus}
    </div>
    <div className="flex flex-col items-center justify-center h-32">
      <FolderIcon />
    </div>
    <div className="mt-2">
      <div className="font-medium">{projectName}</div>
      <div className="flex justify-between items-center mt-1">
        <div
          className={`text-sm ${
            selectedProject._id === _id ? "text-white" : "text-gray-600"
          }`}
        >
          {rooms} Rooms
        </div>
      </div>
    </div>
  </div>
);

const CustomerEnquiry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projects, apiLoading, apiSuccess } = useSelector(
    (state) => state.project
  );
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [activeProjects, setActiveProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    let activeP =
      projects.filter((data) => data.currentStatus !== "Completed") || [];
    setActiveProjects(activeP);
    if (activeP.length > 0) {
      setSelectedProject(activeP[0]);
    }
    setCompletedProjects(
      projects.filter((data) => data.currentStatus === "Completed")
    );
  }, [projects]);

  const fetchProjects = async () => {
    await getProjects(dispatch);
  };

  const handleCreateProject = async (projectData) => {
    var userDetails = localStorage.getItem("user") || "{}";
    userDetails = JSON.parse(userDetails);
    await projectRegistration(dispatch, {
      ...projectData,
      customerId: userDetails._id,
    });
    setIsCreateModalOpen(false);
    fetchProjects();
  };

  const handleEditProject = async (projectData) => {
    await updateProjectsByProjectId(dispatch, selectedProject._id, projectData);
    setIsEditModalOpen(false);
    fetchProjects();
  };

  useEffect(() => {
    if (!apiLoading && apiSuccess) {
      fetchProjects();
    }
  }, [apiLoading]);
  console.log("=====projects", projects, selectedProject);
  return (
    <div className="min-h-screen customer-bg-color">
      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex gap-4" style={{ justifyContent: "space-between" }}>
          <div className="bg-gray-100 rounded-lg p-6" style={{ width: "70%" }}>
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-xl font-medium">My Enquiries</h2>
              <div className="flex items-center gap-4">
                <div className="bg-white px-4 py-2 rounded">
                  Total Projects{" "}
                  <span className="font-semibold">{projects.length}</span>
                </div>
              </div>
            </div>

            {/* Active Enquiries */}
            <div className="mb-8">
              <h3 className="text-lg font-medium mb-4">
                Active Enquiries ({activeProjects.length})
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {activeProjects.map((project, index) => (
                  <ProjectCard
                    key={index}
                    {...project}
                    setSelectedProject={setSelectedProject}
                    selectedProject={selectedProject}
                    projectData={project}
                    navigate={navigate}
                  />
                ))}
              </div>
            </div>

            {/* Completed Orders */}
            <div>
              <h3 className="text-lg font-medium mb-4">
                Completed Orders ({completedProjects.length})
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {completedProjects.map((project, index) => (
                  <ProjectCard key={index} {...project} navigate={navigate} />
                ))}
              </div>
            </div>
          </div>
          {/* Project Timeline */}
          <div className="bg-gray-100 rounded-lg p-6">
            <div className="flex items-center gap-4">
              <button
                onClick={() => setIsCreateModalOpen(true)}
                className="bg-gray-700 text-white px-4 py-2 rounded"
                style={{ width: "100%" }}
              >
                Add New Project
              </button>
            </div>
            {selectedProject && (
              <>
                <ProjectDetails project={selectedProject} setIsEditModalOpen={setIsEditModalOpen}/>
                <ChatBot
                  selectedProject={selectedProject}
                  isChatOpen={isChatOpen}
                  setIsChatOpen={setIsChatOpen}
                />
              </>
            )}
          </div>
        </div>
        <CreateProjectModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          onSubmit={handleCreateProject}
        />
        <EditProjectModal
          selectedProject={selectedProject}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={handleEditProject}
        />
      </main>
    </div>
  );
};

export default CustomerEnquiry;
