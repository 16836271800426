// furdexo-app-frontend/src/reducers/userReducers.js

import { toast } from "react-toastify";
const initialState = {
  users: [],
  user: null,
  message: null,
  // response_code: null,
  // response_status: null,
  apiResponse: null,
  apiLoading: true,
  apiSuccess: false,
  apiResponseStatus: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        message: action.apiResponse.message,
        user: action.apiResponse,
        apiLoading: false,
        apiSuccess: true,
      };
    case "USERS_FETCH_SUCCESS":
      return {
        ...state,
        message: action.apiResponse.message,
        users: action.apiResponse.results,
        apiLoading: false,
        apiSuccess: true,
      };
    case "USER_FETCH_SUCCESS":
      return {
        ...state,
        message: action.apiResponse.message,
        user: action.apiResponse.results,
        apiLoading: false,
        apiSuccess: true,
      };
    case "USER_REGISTER_SUCCESS":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        apiResponse: action.payload,
        apiLoading: false,
        apiSuccess: true,
      };
    case "USER_RESET_PASSWORD_SUCCESS":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        message: action.apiResponse.message,
        apiLoading: false,
        apiSuccess: true,
      };
    case "USER_FORGOT_PASSWORD_SUCCESS":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        message: action.apiResponse.message,
        apiResponseStatus: action.apiResponse.response_status,
        apiLoading: false,
        apiSuccess: true,
      };
    case "USER_UPDATE_SUCCESS":
      toast.success(action.apiResponse.message, {
        position: "bottom-center",
      });
      return {
        ...state,
        message: action.apiResponse.message,
        apiResponseStatus: action.apiResponse.response_status,
        apiLoading: false,
        apiSuccess: true,
      };
    case "USER_LOGOUT_SUCCESS":
      toast.success("User logout successfully.", {
        position: "bottom-center",
      });
      return {
        ...state,
        message: "",
        user: null,
        apiLoading: false,
        apiSuccess: true,
      };
    case "USER_ERROR":
      toast.error(action.apiResponse.message, {
        position: "bottom-center",
      });
      return { ...state, apiResponse: action.apiResponse, apiSuccess: true };
    default:
      return state;
  }
};
