// furdexo-app-frontend/src/store/store.js

import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "../reducers/userReducer";
import { projectReducer } from "../reducers/projectReducer";
import { spaceReducer } from "../reducers/spaceReducer";
import { chatBotReducer } from "../reducers/chatBotReducer";
import { categoryReducer } from "../reducers/categoryReducer";
import { invoiceReducer } from "../reducers/invoiceReducer";

const store = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    space: spaceReducer,
    chatbot: chatBotReducer,
    category: categoryReducer,
    invoice: invoiceReducer
  },
});

export default store;

