const CurrentDate = () => {
  // Get the current date and time
  const currentDate = new Date();

  // Function to format the date and time
  const formatDate = () => {
    const day = String(currentDate.getDate()).padStart(2, "0"); // Day of the month (2 digits)
    const month = currentDate
      .toLocaleString("default", { month: "short" })
      .toUpperCase(); // Month in 3-letter format (e.g., 'JAN', 'FEB')
    const year = currentDate.getFullYear(); // Full year (e.g., 2024)

    return `${day}-${month}-${year}`;
  };

  return formatDate()
};

export default CurrentDate;
