// client/src/components/Order_Details.js

import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Tag,  ChevronDown, X } from "lucide-react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsById } from "../../actions/projectActions";
import { getUserDetails } from "../../actions/userActions";
import {
  getSpaces,
  updateSpaceByProjectId,
} from "../../actions/spaceActions";
import ChatBot from "../../components/ChatBot/ChatBot";
import Processing from "../../components/Processing/Processing";
import { addCategorySubCategoryItem } from "../../actions/categoryActions";
import InvoiceGenerator from "../../shared/InvoiceGenerator/InvoiceGenerator";

const UpdateStatusDropdown = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    // "Revised Cut Sheet Drawings Provided",
    "RFT",
    "RMT",
    "SQFT",
    "SQMT",
    "Nos.",
    "Packing",
    "LS",
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <span className="truncate">{value}</span>
        <ChevronDown
          className={`ml-2 h-4 w-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
          <div className="flex flex-col max-h-[30vh] overflow-y-auto flex-grow absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
            {options.map((option, index) => (
              <button
                key={index}
                className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100
                  ${value === option ? "bg-gray-100" : ""}`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const UpdateGSTCompanyDropdown = ({ value, onChange }) => {
  const { user } = useSelector((state) => state.user);
  console.log("=====UpdateGSTCompanyDropdown", user);
  const [isOpen, setIsOpen] = useState(false);
  const options = user?.companyGst.map((data) => data.gstNumber) || [];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <span className="truncate">{value}</span>
        <ChevronDown
          className={`ml-2 h-4 w-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0" onClick={() => setIsOpen(false)} />
          <div className="flex flex-col max-h-[30vh] overflow-y-auto flex-grow absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
            {options.map((option, index) => (
              <button
                key={index}
                className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100
                  ${value === option ? "bg-gray-100" : ""}`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const AddEstimationRow = ({ isOpen, onClose, orderDetails, spaceAddRow }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    categoryName: "",
    subCategoryName: "",
    name: "",
    hsnCode: "",
    unitOfMeasurement: "",
    ratePerUnit: 0,
    gst: 0,
    discount: 0,
    quantity: 0,
  });
  const { category, apiLoading, apiSuccess } = useSelector(
    (state) => state.category
  );
  useEffect(() => {
    if (!apiLoading) {
      console.log("===Add Row==category", category);
      let spaces = orderDetails.spaces || [];
      console.log("=====spaces", spaces);
      for (var i = 0; i < spaces.length; i++) {
        let space = spaces[i];
        if (spaceAddRow.spaceId === space._id) {
          let items = space.items || [];
          items.push({
            ...formData,
            _id: category.item._id,
            isRowSelected: true,
          });
          console.log("=====items", items);
        }
      }
      onClose(false);
      setFormData({
        categoryName: "",
        subCategoryName: "",
        name: "",
        hsnCode: "",
        unitOfMeasurement: "",
        ratePerUnit: 0,
        gst: 0,
        discount: 0,
        quantity: 0,
      });
    }
  }, [apiLoading]);
  if (!isOpen) return null;

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]:
        field === "name" ||
        field === "categoryName" ||
        field === "subCategoryName" ||
        field === "description" ||
        field === "unitOfMeasurement" ||
        field === "hsnCode"
          ? value
          : parseFloat(value),
    }));
  };
  const handleAddRow = async () => {
    console.log("====formData", formData, spaceAddRow);
    await addCategorySubCategoryItem(dispatch, { ...formData });
  };
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white rounded-lg w-[800px] flex flex-col max-h-[90vh]">
          {/* Fixed Header */}
          <div className="flex justify-between items-center p-6 border-b shrink-0">
            <h2 className="text-xl font-medium">Add Estimation Row</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Scrollable Content */}
          <div className="overflow-y-auto flex-grow">
            <div className="p-6">
              <div className="grid grid-cols-2 gap-8">
                {/* Left Column */}
                <div className="space-y-4">
                  <div>
                    <label>Category</label>
                    <input
                      type="text"
                      placeholder="Enter Category"
                      value={formData.categoryName}
                      onChange={(e) =>
                        handleInputChange("categoryName", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                  <div>
                    <label>SubCategory</label>
                    <input
                      type="text"
                      placeholder="Enter SubCategory"
                      value={formData.subCategoryName}
                      onChange={(e) =>
                        handleInputChange("subCategoryName", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                  <div>
                    <label style={{ paddingTop: "2px !important" }}>
                      Detail
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Detail"
                      value={formData.name}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                  <div>
                    <label style={{ paddingTop: "2px !important" }}>
                      HSN Code
                    </label>
                    <input
                      type="text"
                      placeholder="Enter HSN Code"
                      value={formData.hsnCode}
                      onChange={(e) =>
                        handleInputChange("hsnCode", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                  <div>
                    <label>GST</label>
                    <input
                      type="number"
                      placeholder="Enter GST %"
                      value={formData.gst}
                      onChange={(e) => handleInputChange("gst", e.target.value)}
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label>Unit Of Measurement</label>
                    <UpdateStatusDropdown
                      value={formData.unitOfMeasurement}
                      onChange={(newUnit) =>
                        handleInputChange("unitOfMeasurement", newUnit)
                      }
                    />
                  </div>

                  <div>
                    <label>Discount %</label>
                    <input
                      type="number"
                      placeholder="Enter Discount %"
                      value={formData.discount}
                      onChange={(e) =>
                        handleInputChange("discount", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                  <div>
                    <label>Rate Per Unit</label>
                    <input
                      type="number"
                      placeholder="Enter Rate Per Unit"
                      value={formData.ratePerUnit}
                      onChange={(e) =>
                        handleInputChange("ratePerUnit", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                  <div>
                    <label>Quantity</label>
                    <input
                      type="number"
                      placeholder="Enter Quantity"
                      value={formData.quantity}
                      onChange={(e) =>
                        handleInputChange("quantity", e.target.value)
                      }
                      className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Fixed Footer */}
          <div className="border-t p-6 flex justify-end shrink-0">
            <button
              onClick={handleAddRow}
              className="button-decorator px-6 py-2 rounded text-sm"
            >
              Add New Estimate Item
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ItemRow = ({
  isRowSelected,
  currentStatus,
  item,
  onQuantityChange,
  onRowSelectionChange,
  isRoomSelected,
}) => (
  <tr className="border-t border-gray-200 hover:bg-gray-50">
    <td className="px-6 py-3 text-left small-text uppercase tracking-wider">
      <input
        type="checkbox"
        checked={isRoomSelected || isRowSelected}
        onChange={(e) => onRowSelectionChange(e.target.checked)}
      />
    </td>
    <td className="px-6 py-4 text-sm text-gray-900">{item.categoryName}</td>
    <td className="px-6 py-4">
      <div className="flex items-center">
        <span className="text-sm text-gray-900">{item.name}</span>
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex items-center">
        <span className="text-sm text-gray-900">{item.unitOfMeasurement}</span>
        {/* <ChevronDown className="ml-2 h-4 w-4 text-gray-400 cursor-pointer" /> */}
      </div>
    </td>
    <td className="px-6 py-4">
      <div className="flex items-center justify-center space-x-4">
        <input
          type="number"
          disabled={currentStatus === "Order Placed"}
          value={item.quantity}
          onChange={(e) => onQuantityChange(e.target.value)}
          className="text-center text-sm text-gray-900 bg-transparent border rounded-md px-2 py-1"
        />
      </div>
    </td>
    <td className="px-6 py-4 text-sm text-gray-900 text-right">
      ₹
      {item.ratePerUnit
        ? item.ratePerUnit.toLocaleString("en-IN", { minimumFractionDigits: 2 })
        : "0.00"}
    </td>
    <td className="px-6 py-4 text-sm text-gray-900 text-right">
      {item.gst
        ? item.gst.toLocaleString("en-IN", { minimumFractionDigits: 2 })
        : "0.00"}
    </td>
    <td className="px-6 py-4 text-sm text-gray-900 text-right">
      {item.discount
        ? item.discount.toLocaleString("en-IN", { minimumFractionDigits: 2 })
        : "0.00"}
    </td>
    <td className="px-6 py-4 text-sm text-gray-900 text-right">
      ₹
      {item.grandAmount
        ? item.grandAmount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "0.00"}
    </td>
  </tr>
);

const RoomSection = ({
  space,
  estimate,
  items,
  onQuantityChange,
  onRowSelectionChange,
  onRoomSelectionChange,
  setIsCreateNewEstimateModal,
  setSpaceAddRow,
}) => (
  <>
    <tr className="bg-gray-100">
      <td className="px-6 py-3 text-left small-text uppercase tracking-wider">
        <input
          type="checkbox"
          // indeterminate={true}
          checked={space?.isRoomSelected}
          onChange={(e) => onRoomSelectionChange(e.target.checked)}
        />
      </td>
      <td colSpan="8">
        <div className="flex justify-between px-6 py-3 border-b border-gray-200">
          <span className="text-sm font-medium text-gray-700">
            {space?.name}
          </span>
          {space?.currentStatus === "Order Placed" && (
            <span className="text-sm font-medium text-red-700">
              {space?.currentStatus}
            </span>
          )}
          <span className="text-sm text-gray-500">
            Room Estimate: ₹
            {estimate.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
      </td>
    </tr>
    {items?.map(
      (item, index) =>
        item.label !== "Other" && (
          <ItemRow
            key={index}
            isRowSelected={item?.isRowSelected}
            currentStatus={space?.currentStatus}
            item={item}
            // detail={item.label}
            // unit={item.unit}
            // quantity={item.quantity}
            // rate={item.rate}
            // price={item.quantity * item.rate}
            onQuantityChange={(newQuantity) =>
              onQuantityChange(index, newQuantity)
            }
            onRowSelectionChange={(isRowSelected) =>
              onRowSelectionChange(index, isRowSelected)
            }
            isRoomSelected={space?.isRoomSelected}
          />
        )
    )}
    <tr className="bg-gray-100">
      <td colSpan="9" className="px-6 py-4" style={{ textAlign: "center" }}>
        <button
          onClick={() => {
            setIsCreateNewEstimateModal(true);
            setSpaceAddRow({ spaceId: space._id });
          }}
          className="button-decorator px-4 py-2 rounded"
        >
          Add Row
        </button>
      </td>
    </tr>
  </>
);

const OrderDetails = () => {
  const dispatch = useDispatch();
  const { customerId, projectId } = useParams();
  const [isCreateNewEstimateModal, setIsCreateNewEstimateModal] =
    useState(false);
  const [isInvoiceGeneratorModel, setIsInvoiceGeneratorModel] = useState(false);
  const [spaceAddRow, setSpaceAddRow] = useState({
    spaceId: "",
    category: "",
  });
  const [gstCompany, setGstCompany] = useState("");
  const [showProcessing, setShowProcessing] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    spaces: [],
    totalEstimate: 0,
    totalGSTAmount: 0,
    totalDiscountAmount: 0,
    totalRooms: 0,
    name: "",
  });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { project } = useSelector((state) => state.project);
  const { user } = useSelector((state) => state.user);
  const { spaces, apiLoading, apiSuccess } = useSelector(
    (state) => state.space
  );
  const fetchUserDetails = async () => {
    await getUserDetails(dispatch, customerId);
  };
  const fetchProjectById = async () => {
    await getProjectsById(dispatch, projectId);
  };
  const handleGetSpace = async () => {
    await getSpaces(dispatch, projectId);
  };

  useEffect(() => {
    fetchUserDetails();
    fetchProjectById();
    handleGetSpace();
  }, [projectId, customerId]);

  useEffect(() => {
    const processedRooms = spaces.map((space) => ({
      ...space,
      items: space.spaceSettings,
    }));
    const processedRoom = processedRooms.map((room) => ({
      ...room,
      estimate: calculateRoomEstimate(room.items),
    }));

    setOrderDetails({
      spaces: processedRoom,
      totalEstimate: processedRoom.reduce(
        (sum, room) => sum + (room.isRowSelected ? room.estimate : 0.0),
        0
      ),
      totalRooms: spaces.length,
      totalGSTAmount: 0,
      totalDiscountAmount: 0,
    });
  }, [projectId, spaces]);

  //  console.log("orderDetails", orderDetails);

  const calculateRoomEstimate = useCallback((items) => {
    return items.reduce(
      (sum, item) =>
        sum + (item.isRowSelected && item.grandAmount ? item.grandAmount : 0.0),
      0
    );
  }, []);

  const calculateRoomEstimateGST = useCallback((items) => {
    return items.reduce(
      (sum, item) =>
        sum + (item.isRowSelected && item.gstAmount ? item.gstAmount : 0.0),
      0
    );
  }, []);
  const calculateRoomEstimateDiscount = useCallback((items) => {
    return items.reduce(
      (sum, item) =>
        sum +
        (item.isRowSelected && item.discountAmount ? item.discountAmount : 0.0),
      0
    );
  }, []);

  const handleQuantityChange = useCallback(
    (roomIndex, itemIndex, newQuantity) => {
      setOrderDetails((prev) => {
        const newRooms = [...prev.spaces];
        newRooms[roomIndex] = {
          ...newRooms[roomIndex],
          items: [...newRooms[roomIndex].items],
        };
        let ratePerUnit = newRooms[roomIndex].items[itemIndex].ratePerUnit;
        let gst = newRooms[roomIndex].items[itemIndex].gst;
        let discount = newRooms[roomIndex].items[itemIndex].discount;
        let totalAmount = newQuantity * ratePerUnit;
        let discountAmount = (totalAmount * discount) / 100;
        let gstAmount = ((totalAmount - discountAmount) * gst) / 100;

        newRooms[roomIndex].items[itemIndex] = {
          ...newRooms[roomIndex].items[itemIndex],
          quantity: newQuantity,
          gstAmount: gstAmount,
          discountAmount: discountAmount,
          grandAmount: totalAmount + gstAmount - discountAmount,
        };

        // Update room estimate
        newRooms[roomIndex].estimate = calculateRoomEstimate(
          newRooms[roomIndex].items
        );
        newRooms[roomIndex].gstAmount = calculateRoomEstimateGST(
          newRooms[roomIndex].items
        );
        newRooms[roomIndex].discountAmount = calculateRoomEstimateDiscount(
          newRooms[roomIndex].items
        );

        // Update total estimate
        const totalEstimate = newRooms.reduce(
          (sum, room) => sum + room.estimate,
          0
        );
        const totalGSTAmount = newRooms.reduce(
          (sum, room) => sum + room.gstAmount,
          0
        );
        const totalDiscountAmount = newRooms.reduce(
          (sum, room) => sum + room.discountAmount,
          0
        );

        return {
          ...prev,
          spaces: newRooms,
          totalEstimate,
          totalGSTAmount,
          totalDiscountAmount,
        };
      });
    },
    []
  );

  const handleRowSelectionChange = useCallback(
    (roomIndex, itemIndex, isRowSelected) => {
      setOrderDetails((prev) => {
        const newRooms = [...prev.spaces];
        newRooms[roomIndex] = {
          ...newRooms[roomIndex],
          items: [...newRooms[roomIndex].items],
        };
        if (!isRowSelected) {
          newRooms[roomIndex]["isRoomSelected"] = false;
          prev["isAllRoomSelected"] = false;
        }
        newRooms[roomIndex].items[itemIndex] = {
          ...newRooms[roomIndex].items[itemIndex],
          isRowSelected: isRowSelected,
        };

        const totalItemsSelected = newRooms[roomIndex].items.reduce(
          (sum, item) => sum + (item.isRowSelected ? 1 : 0),
          0
        );

        if (newRooms[roomIndex].items.length === totalItemsSelected) {
          newRooms[roomIndex]["isRoomSelected"] = true;
        }

        const totalRoomSelected = newRooms.reduce(
          (sum, room) => sum + (room.isRoomSelected ? 1 : 0),
          0
        );

        if (newRooms.length === totalRoomSelected) {
          prev["isAllRoomSelected"] = true;
        }

        // Update room estimate
        newRooms[roomIndex].estimate = calculateRoomEstimate(
          newRooms[roomIndex].items
        );
        newRooms[roomIndex].gstAmount = calculateRoomEstimateGST(
          newRooms[roomIndex].items
        );
        newRooms[roomIndex].discountAmount = calculateRoomEstimateDiscount(
          newRooms[roomIndex].items
        );

        // Update total estimate
        const totalEstimate = newRooms.reduce(
          (sum, room) => sum + (room.estimate ? room.estimate : 0),
          0
        );
        const totalGSTAmount = newRooms.reduce(
          (sum, room) => sum + (room.gstAmount ? room.gstAmount : 0),
          0
        );
        const totalDiscountAmount = newRooms.reduce(
          (sum, room) => sum + (room.discountAmount ? room.discountAmount : 0),
          0
        );
        let updateStateSpace = {
          ...prev,
          spaces: newRooms,
          totalEstimate,
          totalGSTAmount,
          totalDiscountAmount,
        };
        console.log("=====updateStateSpace", updateStateSpace);
        return updateStateSpace;
      });
    },
    [calculateRoomEstimate]
  );

  const handleRoomSelectionChange = useCallback(
    (roomIndex, isRoomSelected) => {
      setOrderDetails((prev) => {
        const newRooms = [...prev.spaces];
        newRooms[roomIndex] = {
          ...newRooms[roomIndex],
          items: [...newRooms[roomIndex].items],
          isRoomSelected: isRoomSelected,
        };
        newRooms[roomIndex].items = newRooms[roomIndex].items.map((item) => ({
          ...item,
          isRowSelected: isRoomSelected,
        }));

        const totalRoomSelected = newRooms.reduce(
          (sum, room) => sum + (room.isRoomSelected ? 1 : 0),
          0
        );
        if (newRooms.length === totalRoomSelected) {
          prev["isAllRoomSelected"] = true;
        } else if (!isRoomSelected) {
          prev["isAllRoomSelected"] = false;
        }

        // Update room estimate
        newRooms[roomIndex].estimate = isRoomSelected
          ? calculateRoomEstimate(newRooms[roomIndex].items)
          : 0.0;
        newRooms[roomIndex].gstAmount = calculateRoomEstimateGST(
          newRooms[roomIndex].items
        );
        newRooms[roomIndex].discountAmount = calculateRoomEstimateDiscount(
          newRooms[roomIndex].items
        );

        // Update total estimate
        const totalEstimate = newRooms.reduce(
          (sum, room) => sum + (room.estimate ? room.estimate : 0),
          0
        );
        const totalGSTAmount = newRooms.reduce(
          (sum, room) => sum + (room.gstAmount ? room.gstAmount : 0),
          0
        );
        const totalDiscountAmount = newRooms.reduce(
          (sum, room) => sum + (room.discountAmount ? room.discountAmount : 0),
          0
        );

        return {
          ...prev,
          spaces: newRooms,
          totalEstimate,
          totalGSTAmount,
          totalDiscountAmount,
        };
      });
    },
    [calculateRoomEstimate]
  );

  const handleAllCategorySelectionChange = useCallback(
    (isAllRoomSelected) => {
      setOrderDetails((prev) => {
        var newRooms = [...prev.spaces];

        newRooms = newRooms.map((room) => ({
          ...room,
          isRoomSelected: isAllRoomSelected,
          items: room.items.map((item) => ({
            ...item,
            isRowSelected: isAllRoomSelected,
          })),
        }));

        newRooms = newRooms.map((room) => ({
          ...room,
          estimate: isAllRoomSelected ? calculateRoomEstimate(room.items) : 0.0,
        }));

        // Update total estimate
        const totalEstimate = newRooms.reduce(
          (sum, room) => sum + room.estimate,
          0
        );
        const totalGSTAmount = newRooms.reduce(
          (sum, room) => sum + room.gstAmount,
          0
        );
        const totalDiscountAmount = newRooms.reduce(
          (sum, room) => sum + room.discountAmount,
          0
        );

        return {
          ...prev,
          isAllRoomSelected,
          spaces: newRooms,
          totalEstimate,
          totalGSTAmount,
          totalDiscountAmount,
        };
      });
    },
    [calculateRoomEstimate]
  );

  const handleSubmitReview = async () => {
    setShowProcessing(true);
    var updataPayload = [];
    for (var i = 0; i < orderDetails.spaces.length; i++) {
      let space = orderDetails.spaces[i];
      updataPayload.push({
        spaceId: space._id,
        spaceSettings: space.items,
        currentStatus: "BOM & Estimate generated",
      });
    }
    console.log("updataPayload:", projectId, updataPayload);
    await updateSpaceByProjectId(dispatch, projectId, {
      datas: updataPayload,
    });
    setShowProcessing(false);
  };
  console.log("=====setSpaceAddRow", spaceAddRow);
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex justify-between mb-4">
          {/* Breadcrumb */}
          <div className="text-lg">
            <Link to="/admin" className="large-text">
              Administrator's Dashboard
            </Link>
            <span className="mx-2 text-gray-400">/</span>
            <Link to={`/admin/customer/${customerId}`} className="large-text">
              {user?.firstName} {user?.lastName}'s Orders
            </Link>
            <span className="mx-2 text-gray-400">/</span>
            <span className="large-text">
              {project?.projectName} ({String(spaces.length).padStart(2, "0")})
            </span>
          </div>
          <div className="flex justify-between items-start mb-6">
            <div className="grid grid-cols-2">
              <span className="text-gray-600 text-left">Grand Total</span>
              <div className="flex items-baseline justify-end">
                <span className="text-sm align-top">₹</span>
                <span className="text-2xl font-medium">
                  {orderDetails.totalEstimate.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>

              <span className="text-gray-600 text-left">
                Total Items Estimate
              </span>
              <div className="flex items-baseline justify-end">
                <span className="text-sm align-top">₹</span>
                <span className="text-lg">
                  {(orderDetails.totalEstimate === 0
                    ? 0
                    : orderDetails.totalEstimate - orderDetails.totalGSTAmount
                  ).toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>

              <span className="text-gray-600 text-left">Total GST</span>
              <div className="flex items-baseline justify-end">
                <span className="text-sm align-top">₹</span>
                <span className="text-lg">
                  {(orderDetails.totalEstimate === 0
                    ? 0
                    : orderDetails.totalGSTAmount
                  ).toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Total Estimate */}
        <div className="bg-white shadow-sm rounded-lg p-4 mb-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <Tag className="h-5 w-5 text-gray-500" />
              <span className="text-lg font-medium text-gray-900">
                PRICE PER ITEM
              </span>
            </div>

            <div className="text-right">
              {orderDetails?.spaces && orderDetails.spaces.length > 0 && (
                <div className="flex">
                  <div className="w-full px-4 py-2">
                    <UpdateGSTCompanyDropdown
                      value={gstCompany}
                      onChange={(newGstCompany) => setGstCompany(newGstCompany)}
                    />
                    .
                  </div>
                  <button
                    onClick={() => {
                      if (gstCompany === "") {
                        toast.error("Please select GST", {
                          position: "bottom-center",
                        });
                      } else {
                        setIsInvoiceGeneratorModel(!isInvoiceGeneratorModel);
                      }
                    }}
                    className="w-full mr-2 button-decorator px-4 py-2 rounded"
                  >
                    Generate Invoice
                  </button>
                  <button
                    onClick={handleSubmitReview}
                    className="w-full button-decorator px-4 py-2 rounded"
                  >
                    Generate Estimate
                  </button>
                </div>
              )}
              <ChatBot
                selectedProject={project}
                isChatOpen={isChatOpen}
                setIsChatOpen={setIsChatOpen}
              />
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="bg-white shadow-sm rounded-lg overflow-hidden">
          <table className="min-w-full">
            <thead className="bg-gray-50 border-b border-gray-200">
              <tr>
                <th className="px-6 py-3 text-left small-text uppercase tracking-wider">
                  <input
                    type="checkbox"
                    checked={orderDetails.isAllRoomSelected}
                    onChange={(e) =>
                      handleAllCategorySelectionChange(e.target.checked)
                    }
                  />
                </th>
                <th className="px-6 py-3 text-left small-text uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-3 text-left small-text uppercase tracking-wider">
                  Detail
                </th>
                <th className="px-6 py-3 text-left small-text uppercase tracking-wider">
                  Unit of Measurement
                </th>
                <th className="px-6 py-3 text-center small-text uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-6 py-3 text-right small-text uppercase tracking-wider">
                  Rate / Unit
                </th>
                <th className="px-6 py-3 text-center small-text uppercase tracking-wider">
                  GST %
                </th>
                <th className="px-6 py-3 text-right small-text uppercase tracking-wider">
                  Discount %
                </th>
                <th className="px-6 py-3 text-right small-text uppercase tracking-wider">
                  Price
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {orderDetails.spaces.map((space, spaceIndex) => (
                <RoomSection
                  key={space._id || spaceIndex}
                  space={space}
                  estimate={space.estimate}
                  items={space.items}
                  onQuantityChange={(itemIndex, newQuantity) =>
                    handleQuantityChange(spaceIndex, itemIndex, newQuantity)
                  }
                  onRowSelectionChange={(itemIndex, isRowSelected) =>
                    handleRowSelectionChange(
                      spaceIndex,
                      itemIndex,
                      isRowSelected
                    )
                  }
                  onRoomSelectionChange={(isRoomSelected) =>
                    handleRoomSelectionChange(spaceIndex, isRoomSelected)
                  }
                  setIsCreateNewEstimateModal={setIsCreateNewEstimateModal}
                  setSpaceAddRow={setSpaceAddRow}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Processing show={showProcessing} />
      <AddEstimationRow
        isOpen={isCreateNewEstimateModal}
        onClose={() => setIsCreateNewEstimateModal(false)}
        orderDetails={orderDetails}
        spaceAddRow={spaceAddRow}
      />
      {isInvoiceGeneratorModel && (
        <InvoiceGenerator
          isOpen={isInvoiceGeneratorModel}
          onClose={() => setIsInvoiceGeneratorModel(false)}
          orderDetails={orderDetails}
          customerDetails={user}
          selectGSTCompany={gstCompany}
          project={project}
        />
      )}
    </div>
  );
};

export default OrderDetails;
