// furdexo-app-frontend/src/reducers/userReducers.js

const initialState = {
  message: null,
  apiResponse: null,
  apiLoading: true,
  apiSuccess: false,
  apiResponseStatus: false,
  invoice: {},
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQ_INVOICE":
      return {
        ...state,
        apiLoading: true,
        apiSuccess: false,
        apiResponseStatus: false,
      };
    case "FETCH_INVOICE":
      return {
        ...state,
        message: action.apiResponse.message,
        invoice: action.apiResponse.results,
        apiResponseStatus: action.apiResponse.response_status,
        apiLoading: false,
        apiSuccess: true,
      };
    case "ADD_INVOICE":
      return {
        ...state,
        apiResponse: action.payload,
        invoice: action.apiResponse.results,
        apiLoading: false,
        apiSuccess: true,
      };
    case "ERROR_INVOICE":      
      return { ...state, apiResponse: action.apiResponse, apiSuccess: true };
    default:
      return state;
  }
};
